import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";

import M from "materialize-css";
import Config from "../../../config/Config";
import date from "date-and-time";
import Breadcrumb from "../../components/Breadcrumb";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";

const ShowBill = () => {
  const history = useHistory();
  const { id } = useParams();
  const [isCouponLoaded, setIsDataLoaded] = useState(false);
  const [bill, setBill] = useState({});

  // get Records
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/bills/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setBill(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsDataLoaded(true);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsDataLoaded(true);
        }
      );
  }, []);

  function generatePDF() {
    // Choose the element that your content will be rendered to.
    const element = document.getElementById("invoice");
    // Choose the element and save the PDF for your user.
    html2pdf().from(element).save(`${bill.customerName}-invoice.pdf`);
  }

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* ============================================================== */}

        {/* Bread crumb and right sidebar toggle */}
        {/* ============================================================== */}

        <Breadcrumb title={"BILLS"} pageTitle={"Show Bill"} />
        {/* Add Coupon Form */}
        <div className="row bg-light">
          <div className="col-md-11">
            <div class="page-content container">
              <div class="page-header text-blue-d2">
                <h2 class="page-title text-secondary-d1">
                  Invoice
                  <small class="page-info">
                    <i class="fa fa-angle-double-right text-80"></i>
                    ID: #{bill.orderId}
                  </small>
                </h2>

                <div class="page-tools">
                  <div class="action-buttons">
                    <a
                      class="btn bg-white btn-light mx-1px text-95"
                      href="#"
                      data-title="Print"
                      onClick={generatePDF}
                    >
                      <i class="mr-1 fa fa-download text-primary-m1 text-120 w-2"></i>
                      Download
                    </a>
                    {/* <a
                      class="btn bg-white btn-light mx-1px text-95"
                      href="#"
                      data-title="PDF"
                    >
                      <i class="mr-1 fa fa-file-pdf-o text-danger-m1 text-120 w-2"></i>
                      Export
                    </a> */}
                  </div>
                </div>
              </div>

              <div className="p-5" id="invoice">
                <div class="container px-0">
                  <div class="row mt-4">
                    <div class="col-12 col-lg-12">
                      <div class="row">
                        <div class="col-sm-6">
                          <div>
                            <span class="text-600 text-110 text-blue align-middle">
                              The Cake Inc
                            </span>
                          </div>

                          <div class="text-grey-m2">
                            <div class="my-1">
                              <h6 className="m-0 p-0">Kolkata India</h6>
                            </div>
                            <div class="my-1">
                              <h6 className="m-0 p-0">West Bengal</h6>
                            </div>
                            <div class="my-1">
                              <i class="fa fa-phone fa-flip-horizontal text-secondary"></i>{" "}
                              <b class="text-600">7890151818</b>
                            </div>
                            <div class="my-1">
                              <i class="fa fa-envelope fa-flip-horizontal text-secondary"></i>{" "}
                              <b class="text-600">contact@thecakeinc.com</b>
                            </div>
                          </div>
                        </div>
                        {/* /.col */}

                        <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                          <img
                            style={{ width: "150px", marginLeft: "5px" }}
                            src="/assets/images/logo.png"
                            className="dark-logo"
                          />
                        </div>
                        {/* /.col */}
                      </div>

                      <hr class="row brc-default-l1 mx-n1 mb-4" />

                      <div class="row">
                        <div class="col-sm-6">
                          <div>
                            <span class="text-sm text-grey-m2 align-middle">
                              <b>Bill To:</b>
                            </span>
                            <br />
                            <span class="text-600 text-110 text-blue align-middle">
                              {bill.customerName}
                            </span>
                          </div>
                          <div class="text-grey-m2">
                            <div class="my-1">
                              <h6 className="m-0 p-0">
                                {bill.customerAddress}
                              </h6>
                            </div>
                            <div class="my-1">
                              <h6 className="m-0 p-0">{bill.customerCity}</h6>
                            </div>
                            <div class="my-1">
                              <i class="fa fa-phone fa-flip-horizontal text-secondary"></i>{" "}
                              <b class="text-600">{bill.customerMobile}</b>
                            </div>
                          </div>
                        </div>
                        {/* /.col */}

                        <div class="text-95 col-sm-6 align-self-start d-sm-flex justify-content-end">
                          <hr class="d-sm-none" />
                          <div class="text-grey-m2">
                            <div class="mt-1 mb-2 text-secondary-m1 text-600 text-125">
                              Invoice
                            </div>

                            <div class="my-2">
                              <b>
                                <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                                <span class="text-600 text-90">ID:</span> #
                                {bill.orderId}
                              </b>
                            </div>

                            <div class="my-2">
                              <b>
                                <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                                <span class="text-600 text-90">
                                  Issue Date:
                                </span>{" "}
                                {date.format(new Date(bill.date), "DD-MM-YYYY")}
                              </b>
                            </div>

                            {/* <div class="my-2">
                            <i class="fa fa-circle text-blue-m2 text-xs mr-1"></i>{" "}
                            <span class="text-600 text-90">Status:</span>{" "}
                            <span class="badge badge-warning badge-pill px-25">
                              Unpaid
                            </span>
                          </div> */}
                          </div>
                        </div>
                        {/* /.col */}
                      </div>

                      <div class="mt-4">
                        <div class="row text-600 text-white bgc-default-tp1 py-25">
                          <div class="d-none d-sm-block col-1">SN</div>
                          <div class="col-9 col-sm-5">Name</div>
                          <div class="d-none d-sm-block col-4 col-sm-2">
                            Qty
                          </div>
                          <div class="d-none d-sm-block col-sm-2">Price</div>
                          <div class="col-2">Amount</div>
                        </div>

                        <div class="text-95 text-secondary-d3">
                          <div class="row mb-2 mb-sm-0 py-25">
                            <div class="d-none d-sm-block col-1">
                              <h6>1</h6>
                            </div>
                            <div class="col-9 col-sm-5">
                              <h6 className="m-0 p-0">{bill.product}</h6>
                              <p className="m-0 p-0">Flavour: {bill.flavour}</p>
                            </div>
                            <div class="d-none d-sm-block col-2">
                              <h6>{bill.quantity}</h6>
                            </div>
                            <div class="d-none d-sm-block col-2 text-95">
                              <h6>₹{bill.price}</h6>
                            </div>
                            <div class="col-2 text-secondary-d2">
                              <h6>₹{bill.price * bill.quantity}</h6>
                            </div>
                          </div>
                        </div>

                        <div class="row border-b-2 brc-default-l2"></div>
                        <hr />

                        <div class="row mt-3">
                          <div class="col-12 col-sm-7 text-grey-d2 text-95 mt-2 mt-lg-0">
                            {/* Extra note such as company or payment information... */}
                          </div>

                          <div class="col-12 col-sm-5 text-grey text-90 order-first order-sm-last">
                            {bill.customAddons ? (
                              <div class="row my-2">
                                <div class="col-7 text-right">
                                  <h6 className="m-0 p-0">Custom Addons</h6>
                                </div>
                                <div class="col-5">
                                  <span class="text-120 text-secondary-d1">
                                    <h6 className="m-0 p-0">
                                      ₹{bill.customAddons}
                                    </h6>
                                  </span>
                                </div>
                              </div>
                            ) : null}

                            <div class="row my-2">
                              <div class="col-7 text-right">
                                <h6 className="m-0 p-0">Shipping Charges</h6>
                              </div>
                              <div class="col-5">
                                <span class="text-120 text-secondary-d1">
                                  <h6 className="m-0 p-0">
                                    ₹{bill.shippingCharges}
                                  </h6>
                                </span>
                              </div>
                            </div>

                            <div class="row my-2 align-items-center bgc-primary-l3 p-2">
                              <div class="col-7 text-right">
                                <h5 className="m-0 p-0">Total Amount</h5>
                              </div>
                              <div class="col-5">
                                <h5 className="m-0 p-0">₹{bill.totalAmount}</h5>
                              </div>
                            </div>

                            {bill.advancePayment ? (
                              <>
                                <div class="row my-2">
                                  <div class="col-7 text-right">
                                    <h6 className="m-0 p-0">Advance Payment</h6>
                                  </div>
                                  <div class="col-5">
                                    <span class="text-120 text-secondary-d1">
                                      <h6 className="m-0 p-0">
                                        ₹{bill.advancePayment}
                                      </h6>
                                    </span>
                                  </div>
                                </div>

                                <div class="row my-2">
                                  <div class="col-7 text-right">
                                    <h6 className="m-0 p-0">Pending Payment</h6>
                                  </div>
                                  <div class="col-5">
                                    <span class="text-120 text-secondary-d1">
                                      <h6 className="m-0 p-0">
                                        ₹{bill.pendingPayment}
                                      </h6>
                                    </span>
                                  </div>
                                </div>
                              </>
                            ) : null}
                          </div>
                        </div>

                        <hr />

                        <div>
                          <div className="">
                            <h6>
                              Delivery Date :{" "}
                              {date.format(
                                new Date(bill.deliveryDate),
                                "DD-YY-YYYY"
                              )}
                            </h6>
                            <h6>Delivery Time : {bill.deliveryTime}</h6>
                          </div>

                          <hr />

                          <span class="text-secondary-d1 text-105">
                            <p>Thank you for doing buseness with us.</p>
                          </span>
                          {/* <a
                          href="#"
                          class="btn btn-info btn-bold px-4 float-right mt-3 mt-lg-0"
                        >
                          Pay Now
                        </a> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowBill;
