import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import date from "date-and-time";
import ReactHtmlParser from "react-html-parser";

const CreateOrder = () => {
  const history = useHistory();

  // Customer Details
  const [customer, setCustomer] = useState({
    name: "",
    mobile: "",
    email: "",
  });
  const [customerCreating, setCustomerCreating] = useState(false);
  let localCustomer = localStorage.getItem("customer");
  const [createdCustomer, setCreatedCustomer] = useState(
    JSON.parse(localCustomer) || {}
  );

  // Search Product
  const [serarchedProduct, setSelectedProduct] = useState([]);

  // Form Data
  const [formData, setFormData] = useState({
    products: [],
    adonProducts: [],
  });
  const [makingOrder, setMakingOrder] = useState(false);

  const [address, setAddress] = useState({
    name: "",
    mobile: "",
    alternateMobile: "",
    address: "",
    landmark: "",
    city: "",
    addressType: "HOME",
    pincode: "",
  });
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [discountWithCoupon, setDiscountWithCoupon] = useState("");

  const [coupons, setCoupons] = useState([]);
  const [enteredCoupon, setEnteredCoupon] = useState("");

  const [appliedCoupon, setAppliedCoupon] = useState({
    code: "",
    discount: "",
    discountType: "",
  });
  const [couponApplying, setCouponApplying] = useState(false);

  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState({});

  const [addonsProducts, setAddonsProducts] = useState([]);
  const [addonTotalAmount, setAddonTotalAmount] = useState(0);
  const [orderDate, setOrderDate] = useState(new Date());

  // Get Addons Product
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/adon-product?skip=0&limit=40`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setAddonsProducts(data.body);
        } else {
          console.log(
            "Error Occured While loading Addons Products : AdonProducts"
          );
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
      });
  }, []);

  // Get all coupon
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/coupon?status=Active&limit=20000&skip=0`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setCoupons(data.body);
        } else {
          console.log("Error Occured While loading coupons : checkout");
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
      });
  }, []);

  // Get Shipping methods
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/shipping-method`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setShippingMethods(data.body);
        } else {
          console.log(
            "Error Occured While loading shippingMethods : ProductDetails"
          );
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
      });
  }, []);

  // Calculate total amount
  useEffect(() => {
    let { products, adonProducts } = formData;
    if (products.length) {
      // Products
      let array = products.map(
        (product) => Number(product.price) * Number(product.quantity)
      );
      let sub_total = array.reduce((a, b) => a + b, 0);

      // addon products
      let addonArray = adonProducts.map(
        (product) => Number(product.price) * Number(product.quantity)
      );
      let addon_total = addonArray.reduce((a, b) => a + b, 0);

      // calculate coupon discount amount
      let dis_with_coupon = 0;
      if (appliedCoupon.code) {
        if (appliedCoupon.discountType === "PERCENTAGE") {
          dis_with_coupon = (sub_total * appliedCoupon.discount) / 100;
        } else {
          dis_with_coupon = appliedCoupon.discount;
        }
      }

      // Calculate Total
      let total = 0;
      if (selectedShippingMethod.amount) {
        total =
          sub_total +
          addon_total +
          parseInt(selectedShippingMethod.amount) -
          dis_with_coupon;
      } else {
        total = sub_total + addon_total - dis_with_coupon;
      }

      if (selectedShippingMethod.amount) {
        setSubTotalAmount(sub_total + parseInt(selectedShippingMethod.amount));
      } else {
        setSubTotalAmount(sub_total);
      }
      setAddonTotalAmount(addon_total);
      setDiscountWithCoupon(dis_with_coupon);
      setTotalAmount(total);
    }
  }, [formData, appliedCoupon, selectedShippingMethod]);

  // handleCouponVerification
  const handleCouponVerification = () => {
    // Check coupon code is valid or not
    if (!coupons.some((c) => c.code === enteredCoupon)) {
      M.toast({ html: "This coupon code is invalid.", classes: "bg-danger" });
      return;
    }

    // Check coupon amount
    const filteredCoupon = coupons.filter((c) => c.code === enteredCoupon);
    if (
      filteredCoupon.length &&
      subTotalAmount < filteredCoupon[0].minimumAmount
    ) {
      M.toast({
        html: `Amount is Must at least Rs ${filteredCoupon[0].minimumAmount}`,
        classes: "bg-danger",
      });
      return;
    }

    setCouponApplying(true);
    fetch(
      `${Config.SERVER_URL}/coupon/verifyByAdmin/${enteredCoupon}/${createdCustomer._id}`,
      {
        method: "GET", // or 'PUT'
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setAppliedCoupon({
            code: data?.body?.code,
            discount: data?.body?.discount,
            discountType: data?.body?.discountType,
          });

          M.toast({ html: data.message, classes: "bg-success" });
        } else {
          M.toast({ html: data.message, classes: "bg-danger" });
        }
        setCouponApplying(false);
      })
      .catch((error) => {
        M.toast({ html: error.message, classes: "bg-danger" });
        setCouponApplying(false);
      });
  };

  // handelRemoveCoupon
  const handelRemoveCoupon = function () {
    // removing the copoon
    setAppliedCoupon({
      code: "",
      discount: "",
      discountType: "",
    });
    M.toast({ html: "Coupon removed successfully", classes: "bg-success" });
    setEnteredCoupon("");
  };

  // handleCreateOrder
  const handleCreateOrder = async function () {
    setMakingOrder(true);

    let data = {
      products: formData.products,
      adonProducts: formData.adonProducts,
      shippingAddress: address,
      subtotal: subTotalAmount,
      totalAmount: totalAmount,
      paymentMethod: "ONLINE",
      shippingMethod: selectedShippingMethod,
      paymentStatus: "Credit",
      customerId: createdCustomer._id,
      adonTotalAmount: addonTotalAmount,
      orderType: "OFFLINE",
      createdAt: orderDate,
    };

    // coupon
    if (appliedCoupon.code) {
      data.coupon = appliedCoupon;
      data.discountWithCoupon = discountWithCoupon;
    }

    try {
      const response = await fetch(`${Config.SERVER_URL}/order/create`, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      });
      const result = await response.json();
      if (result.status == 200) {
        M.toast({ html: result.message, classes: "bg-success" });
        setAppliedCoupon({ code: "", discount: "", discountType: "" });
        setEnteredCoupon("");
        handleClearLocalData();
      } else {
        const errorKeys = Object.keys(result.error);
        errorKeys.forEach((key) => {
          M.toast({ html: result.error[key], classes: "bg-danger" });
        });
        M.toast({ html: result.message, classes: "bg-danger" });
      }
      setMakingOrder(false);
    } catch (error) {
      M.toast({ html: error.message, classes: "bg-danger" });
      setMakingOrder(false);
    }
  };

  const handleClearLocalData = function () {
    localStorage.removeItem("customer");
    setCreatedCustomer({});
    setFormData({
      products: [],
      adonProducts: [],
    });
  };

  // handleCreateCustomer
  const handleCreateCustomer = async (evt) => {
    evt.preventDefault();
    setCustomerCreating(true);

    try {
      const response = await fetch(
        Config.SERVER_URL + "/customer/createCustomer",
        {
          method: "POST",
          body: JSON.stringify(customer),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
          },
        }
      );
      const result = await response.json();

      if (result.status == 200) {
        let data = {
          name: result?.body?.name,
          mobile: result?.body?.mobile,
          _id: result?.body?._id,
        };
        localStorage.setItem("customer", JSON.stringify(data));
        setCreatedCustomer(data);
        M.toast({ html: result.message, classes: "bg-success" });
      } else {
        const errorKeys = Object.keys(result.error);
        errorKeys.forEach((key) => {
          M.toast({ html: result.error[key], classes: "bg-danger" });
        });
        M.toast({ html: result.message, classes: "bg-danger" });
      }
      setCustomerCreating(false);
    } catch (error) {
      setCustomerCreating(false);
      M.toast({ html: error.message, classes: "bg-danger" });
    }
  };

  // handleSearchProduct
  const handleSearchProduct = async function (event) {
    event.preventDefault();
    try {
      const response = await fetch(
        `${Config.SERVER_URL}/product/search?query=${event?.target?.value}&limit=20`
      );
      const result = await response.json();
      if (result.status === 200) {
        setSelectedProduct(result.body);
      } else {
        M.toast({ html: result.message, classes: "bg-danger" });
      }
    } catch (error) {
      M.toast({ html: error.message, classes: "bg-danger" });
    }
  };

  // make object
  const makeObject = function (product) {
    const productDetails = {};
    productDetails.name = product.name;
    productDetails.slug = product.slug;
    productDetails.parentCategories = product.parentCategories;
    productDetails.categories = product.categories;
    productDetails.productId = product._id;
    productDetails.quantity = 1;
    productDetails.price = "";
    productDetails.mrp = "";
    productDetails.weight = "";
    productDetails.flavour = product.flavour.name;
    productDetails.shape = product.shape.name;
    productDetails.cakeType = product.type.name;
    productDetails.image = product.defaultImage;
    productDetails.images = product.images;
    productDetails.breadType = product.breadType;
    productDetails.messageOnCake = "";
    productDetails.imageOnCake = "";

    return productDetails;
  };

  // handleAddProduct
  const handleAddProduct = function (product) {
    let { products } = formData;
    let filter = products.filter((p) => p.productId == product._id);
    if (filter.length) {
    } else {
      let productDetails = makeObject(product);
      products.push(productDetails);
    }

    setFormData({ ...formData, products });
  };

  // handleRemoveProduct
  const handleRemoveProduct = function (productId) {
    let { products } = formData;
    let filter = products.filter((p) => p.productId != productId);

    setFormData({ ...formData, products: filter });
  };

  //  handleUpdateCakeMessage
  const handleUpdateCakeMessage = function (productId, message) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      products[index].messageOnCake = message;
      setFormData({ ...formData, products });
    }
  };

  //  handleWeightNPrice
  const handleWeightNPrice = function (productId, price) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      products[index].price = price.sellingPrice;
      products[index].mrp = price.mrp;
      products[index].weight = price.weight;
      setFormData({ ...formData, products });
    }
  };

  // handleDecreaseQty
  const handleDecreaseQty = function (productId) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      if (products[index].quantity > 1) {
        products[index].quantity = parseInt(products[index].quantity) - 1;
        setFormData({ ...formData, products });
      }
    }
  };
  // handleIncreaseQty
  const handleIncreaseQty = function (productId) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      products[index].quantity = parseInt(products[index].quantity) + 1;
      setFormData({ ...formData, products });
    }
  };

  // getQuantity
  const getQuantity = function (productId) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return products[index].quantity;
    }
    return 1;
  };

  // getMessageOnCake
  const getMessageOnCake = function (productId) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return products[index].messageOnCake;
    }
    return "";
  };

  // getSelectedWeigntNPrice
  const getSelectedWeigntNPrice = function (productId) {
    let { products } = formData;
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return products[index].weight;
    }
    return "";
  };

  // ======================= Addons Product Section =======================
  // handleAddAddonsProduct
  const handleAddAddonsProduct = function (product) {
    let { adonProducts } = formData;
    let filter = adonProducts.filter((p) => p.productId == product._id);
    if (filter.length) {
    } else {
      let productDetails = {
        name: product.name,
        slug: product.slug,
        productId: product._id,
        quantity: 1,
        price: product.sellingPrice,
        image: product.image,
      };
      adonProducts.push(productDetails);
    }

    setFormData({ ...formData, adonProducts });
  };

  // handleRemoveAddonProduct
  const handleRemoveAddonProduct = function (productId) {
    let { adonProducts } = formData;
    let filter = adonProducts.filter((p) => p.productId != productId);

    setFormData({ ...formData, adonProducts: filter });
  };

  // handleDecreaseAddonQty
  const handleDecreaseAddonQty = function (productId) {
    let { adonProducts } = formData;
    let index = adonProducts.findIndex((p) => p.productId == productId);
    if (index != -1) {
      if (adonProducts[index].quantity > 1) {
        adonProducts[index].quantity =
          parseInt(adonProducts[index].quantity) - 1;
        setFormData({ ...formData, adonProducts });
      }
    }
  };
  // handleIncreaseAddonQty
  const handleIncreaseAddonQty = function (productId) {
    let { adonProducts } = formData;
    let index = adonProducts.findIndex((p) => p.productId == productId);
    if (index != -1) {
      adonProducts[index].quantity = parseInt(adonProducts[index].quantity) + 1;
      setFormData({ ...formData, adonProducts });
    }
  };

  // getAddonQuantity
  const getAddonQuantity = function (productId) {
    let { adonProducts } = formData;
    let index = adonProducts.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return adonProducts[index].quantity;
    }
    return 1;
  };

  return (
    <>
      <div className="page-wrapper px-0 pt-0">
        <div className="container-fluid">
          {/* <!-- ============================================================== --> */}
          {/* <!-- Bread crumb and right sidebar toggle --> */}
          {/* <!-- ============================================================== --> */}
          <Breadcrumb title={"MAKE ORDER"} pageTitle={"Make Order"} />

          {/* Add Form */}
          <div className="row">
            <div className={"col-md-11 mx-auto"}>
              <form className="form-horizontal form-material">
                {/* Customer Details */}
                {!createdCustomer._id ? (
                  <div className={"row shadow-sm bg-white py-3"}>
                    <div className="col-md-12">
                      <h3 className={"my-3 text-info"}>Customer Details</h3>
                    </div>

                    {/* CUSTOMER NAME */}
                    <div className={"form-group col-md-6"}>
                      <label htmlFor="" className="text-dark h6 active">
                        CUSTOMER NAME
                      </label>
                      <input
                        type="text"
                        value={customer.name}
                        onChange={(evt) =>
                          setCustomer({ ...customer, name: evt.target.value })
                        }
                        className="form-control"
                        placeholder={"Customer Name"}
                      />
                    </div>

                    {/* CUSTOMER MOBILE */}
                    <div className={"form-group col-md-6"}>
                      <label htmlFor="" className="text-dark h6 active">
                        CUSTOMER MOBILE
                      </label>
                      <input
                        type="text"
                        value={customer.mobile}
                        onChange={(evt) =>
                          setCustomer({ ...customer, mobile: evt.target.value })
                        }
                        className="form-control"
                        placeholder={"Customer Mobile"}
                      />
                    </div>

                    {/* CUSTOMER EMAIL */}
                    <div className={"form-group col-md-6"}>
                      <label htmlFor="" className="text-dark h6 active">
                        CUSTOMER EMAIL
                      </label>
                      <input
                        type="text"
                        value={customer.email}
                        onChange={(evt) =>
                          setCustomer({ ...customer, email: evt.target.value })
                        }
                        className="form-control"
                        placeholder={"Customer Email"}
                      />
                    </div>

                    <div className="col-md-6">
                      <button
                        className="btn btn-info rounded px-3 py-2"
                        type={"button"}
                        onClick={handleCreateCustomer}
                      >
                        {!customerCreating ? (
                          <div>
                            Continue <i className="fas fa-angle-right"></i>
                          </div>
                        ) : (
                          <div>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading..
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                ) : null}

                {/* Order Details */}
                {createdCustomer._id ? (
                  <div className={"row shadow-sm bg-white mt-3 py-3"}>
                    <div className="col-md-12 d-flex justify-content-between align-items-center">
                      <h3 className={"text-info"}>
                        Order Details
                        <span className="badge badge-info">
                          {createdCustomer.name}
                        </span>
                        <button
                          onClick={handleClearLocalData}
                          type="button"
                          className="btn btn-danger"
                        >
                          X
                        </button>
                      </h3>

                      <div className="">
                        <button
                          className="btn btn-info"
                          type="button"
                          data-toggle="modal"
                          data-target="#productModal"
                        >
                          Add Product
                        </button>

                        {formData?.products?.length ? (
                          <button
                            className="btn btn-warning ml-1"
                            type="button"
                            data-toggle="modal"
                            data-target="#addonsProductModal"
                          >
                            Add Add Ons Product
                          </button>
                        ) : null}
                      </div>
                    </div>
                    {formData?.products?.length ? (
                      <div className={"form-group col-md-12"}>
                        <div className="table-responsive">
                          <table className="table">
                            <tr>
                              <th>PRODUCT</th>
                              <th>MESSAGE</th>
                              <th>PRICE</th>
                              <th>ACTION</th>
                            </tr>
                            {formData?.products?.map((product, index) => {
                              return (
                                <tr>
                                  <td className="d-flex">
                                    <div className="">
                                      <img
                                        src={product.image}
                                        alt=""
                                        style={{
                                          height: "130px",
                                          weight: "130px",
                                          borderRadius: "20px",
                                        }}
                                      />
                                    </div>
                                    <div className="ml-2">
                                      <h6 className="p-0 m-0">
                                        {product.name}
                                      </h6>
                                      <p className="p-0 m-0">
                                        {product.flavour}
                                      </p>
                                      <p className="p-0 m-0">{product.shape}</p>
                                    </div>
                                  </td>
                                  <td>{product?.messageOnCake}</td>
                                  <td>{`${product.quantity} X ${product.price}`}</td>
                                  <td>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        handleRemoveProduct(product.productId);
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan={4}>
                                <h5>Addons Products</h5>
                              </td>
                            </tr>
                            {formData?.adonProducts?.map((product, index) => {
                              return (
                                <tr>
                                  <td className="d-flex">
                                    <div className="">
                                      <img
                                        src={product.image}
                                        alt=""
                                        style={{
                                          height: "80px",
                                          weight: "80px",
                                          borderRadius: "20px",
                                        }}
                                      />
                                    </div>
                                    <div className="ml-2">
                                      <h6 className="p-0 m-0">
                                        {product.name}
                                      </h6>
                                    </div>
                                  </td>
                                  <td>{product?.messageOnCake}</td>
                                  <td>{`${product.quantity} X ${product.price}`}</td>
                                  <td>
                                    <button
                                      className="btn btn-danger"
                                      type="button"
                                      onClick={() => {
                                        handleRemoveAddonProduct(
                                          product.productId
                                        );
                                      }}
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td></td>
                              <td></td>
                              <td>Subtotal</td>
                              <td>{subTotalAmount}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>Addon Total Amount</td>
                              <td>{addonTotalAmount || 0}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>Coupon Discount ({appliedCoupon.code})</td>
                              <td>{discountWithCoupon || 0}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>Delivery Charge</td>
                              <td>{selectedShippingMethod.amount || 0}</td>
                            </tr>
                            <tr>
                              <td></td>
                              <td></td>
                              <td>Total</td>
                              <td>{totalAmount}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </form>
            </div>

            {/* Delivery Address */}
            {createdCustomer._id ? (
              <div className="col-md-11 mx-auto">
                {formData?.products?.length ? (
                  <div className="row shadow-sm bg-white mt-3 py-3">
                    <div className="col-md-12 mt-3">
                      <h4 className="text-info">Delivery Address</h4>
                      <form className="form-horizontal form-material">
                        <div className="row">
                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              Name
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Name"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  name: evt.target.value,
                                })
                              }
                              value={address.name}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              Mobile
                            </label>
                            <input
                              type="tel"
                              placeholder="Enter Mobile"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  mobile: evt.target.value,
                                })
                              }
                              value={address.mobile}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              Alternative Mobile
                            </label>
                            <input
                              type="tel"
                              placeholder="Enter Mobile"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  alternateMobile: evt.target.value,
                                })
                              }
                              value={address.alternateMobile}
                            />
                          </div>

                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              Address
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Address"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  address: evt.target.value,
                                })
                              }
                              value={address.address}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              Landmark
                            </label>
                            <input
                              type="text"
                              placeholder="Enter Landmark"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  landmark: evt.target.value,
                                })
                              }
                              value={address.landmark}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              City
                            </label>
                            <input
                              type="text"
                              placeholder="Enter City"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  city: evt.target.value,
                                })
                              }
                              value={address.city}
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="" className="text-dark h6 active">
                              Pincode
                            </label>
                            <input
                              type="tel"
                              placeholder="Enter Pincode"
                              className="form-control"
                              onChange={(evt) =>
                                setAddress({
                                  ...address,
                                  pincode: evt.target.value,
                                })
                              }
                              value={address.pincode}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* Coupon Details */}
            {createdCustomer._id ? (
              <div className="col-md-11 mx-auto">
                {formData?.products?.length ? (
                  <div className="row shadow-sm bg-white mt-3 py-3">
                    <div className="col-md-12 mt-3 form-horizontal form-material">
                      <h4 className="text-info">Coupon Details</h4>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="" className="text-dark h6">
                              Coupon Code
                            </label>
                            <input
                              type="text"
                              value={enteredCoupon}
                              onChange={(evt) => {
                                setEnteredCoupon(evt.target.value);
                              }}
                              className="form-control"
                              placeholder="Enter your coupon code"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            {/* Apply & Remove Coupon Button */}
                            {appliedCoupon.code ? (
                              <button
                                type="button"
                                className="btn btn-danger"
                                onClick={handelRemoveCoupon}
                              >
                                Remove Coupon
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-info"
                                onClick={handleCouponVerification}
                              >
                                {couponApplying ? "Loading..." : "Apply Coupon"}
                              </button>
                            )}

                            <button
                              type="button"
                              className="btn btn-warning ml-2"
                              data-toggle="modal"
                              data-target="#availableCouponModal"
                            >
                              Available Coupon
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}

            {/* Shipping Methods */}
            {createdCustomer._id ? (
              <div className="col-md-11 mx-auto">
                {formData?.products?.length ? (
                  <div className="row shadow-sm bg-white mt-3 py-3">
                    <div className="col-md-12 mt-3">
                      <h4 className="text-info">Shipping Methods</h4>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="" className="text-dark h6">
                              Order Date
                            </label>
                            <input
                              type="date"
                              value={date.format(
                                new Date(orderDate),
                                "YYYY-MM-DD"
                              )}
                              onChange={(evt) => {
                                setOrderDate(evt.target.value);
                              }}
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <label htmlFor="" className="text-dark h6">
                              Shipping Date
                            </label>
                            <input
                              type="date"
                              value={date.format(
                                new Date(selectedShippingMethod.date),
                                "YYYY-MM-DD"
                              )}
                              onChange={(evt) => {
                                setSelectedShippingMethod({
                                  ...selectedShippingMethod,
                                  date: evt.target.value,
                                });
                              }}
                              className="form-control"
                              placeholder="Select Shipping Date"
                            />
                          </div>
                        </div>
                      </div>
                      <div id="accordion">
                        {shippingMethods.map((method, index) => {
                          return (
                            <div className="card">
                              <div className="card-header" id="headingOne">
                                <h5 className="mb-0">
                                  <h6
                                    className="h6"
                                    style={{ cursor: "pointer" }}
                                    data-toggle="collapse"
                                    data-target={`#collapse-${index}`}
                                    aria-expanded="true"
                                    aria-controls={`collapse-${index}`}
                                  >
                                    {`${method.name} Rs ${method.amount}/-`}
                                  </h6>
                                </h5>
                              </div>

                              <div
                                id={`collapse-${index}`}
                                className={`collapse ${
                                  index == 0 ? "show" : ""
                                }`}
                                aria-labelledby={`collapse-${index}`}
                                data-parent="#accordion"
                              >
                                <div className="card-body">
                                  {method?.shippingTimes?.map(
                                    (timeDetails, index) => {
                                      return (
                                        <div className="custom-control custom-radio pl-0 ml-0 mb-2">
                                          <input
                                            type="radio"
                                            id={`time-${index}`}
                                            name="photoCake"
                                            value={true}
                                            // checked={
                                            //   product?.isPhotoCake?.toString() ==
                                            //   "true"
                                            //     ? true
                                            //     : false
                                            // }
                                            onChange={(evt) =>
                                              setSelectedShippingMethod({
                                                ...selectedShippingMethod,
                                                method: method.name,
                                                amount: method.amount,
                                                startTime:
                                                  timeDetails.startTime,
                                                endTime: timeDetails.endTime,
                                              })
                                            }
                                            className="custom-control-input"
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`time-${index}`}
                                          >
                                            {date.transform(
                                              timeDetails.startTime,
                                              "HH:mm",
                                              "hh:mm A"
                                            )}
                                            -
                                            {date.transform(
                                              timeDetails.endTime,
                                              "HH:mm",
                                              "hh:mm A"
                                            )}
                                          </label>
                                        </div>
                                      );
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>

                    <div className={"form-group col-md-12"}>
                      <button
                        className="btn btn-info rounded px-3 py-2"
                        type={"button"}
                        onClick={handleCreateOrder}
                      >
                        {!makingOrder ? (
                          <div>
                            <i className="fas fa-plus"></i> Make Order
                          </div>
                        ) : (
                          <div>
                            <span
                              className="spinner-border spinner-border-sm mr-1"
                              role="status"
                              aria-hidden="true"
                            ></span>
                            Loading..
                          </div>
                        )}
                      </button>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </div>
      </div>

      {/* ===================================== Modal Section ===================================== */}
      {/* Product Modal */}
      <div
        className="modal fade"
        id="productModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="productModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="productModalLabel">
                Find Product
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <form className="form-horizontal form-material">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Enter product name"
                      onChange={handleSearchProduct}
                    />
                  </div>
                </form>
              </div>

              {/* Search Products */}
              <div className="">
                {serarchedProduct.map((product) => {
                  return (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              src={product.defaultImage}
                              alt=""
                              className="img img-fluid"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "35px",
                              }}
                            />
                          </div>
                          <div className="col-md-8">
                            <h5 className="m-0 p-0">{product.name}</h5>
                            <p className="p-0 m-0">
                              Flavour : {product?.flavour?.name}
                            </p>
                            <p className="p-0 m-0">
                              Shape : {product?.shape?.name}
                            </p>

                            <p className="p-0 m-0">
                              Price : <i className="fa fa-inr"></i>
                              {product?.priceVariants[0]?.sellingPrice}
                            </p>

                            {formData?.products?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <>
                                {/* Cake Message */}
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control cake-message"
                                    placeholder="Enter message on cake"
                                    value={getMessageOnCake(product._id)}
                                    onChange={(evt) => {
                                      handleUpdateCakeMessage(
                                        product._id,
                                        evt.target.value
                                      );
                                    }}
                                  />
                                </div>

                                {/* Quantity */}
                                <div className="cart-wrapper">
                                  <button
                                    className="minus"
                                    onClick={() => {
                                      handleDecreaseQty(product._id);
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    readOnly
                                    value={getQuantity(product._id)}
                                    className="qty"
                                  />

                                  <button
                                    className="plus"
                                    onClick={() => {
                                      handleIncreaseQty(product._id);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>

                                {/* Price */}
                                <div className="d-flex">
                                  {product?.priceVariants?.map((price) => {
                                    return (
                                      <div
                                        className={`m-2 price-wrapper ${
                                          getSelectedWeigntNPrice(
                                            product._id
                                          ) == price.weight
                                            ? "bg-warning"
                                            : null
                                        }`}
                                        onClick={() => {
                                          handleWeightNPrice(
                                            product._id,
                                            price
                                          );
                                        }}
                                      >
                                        <p className="weight">{price.weight}</p>
                                        <p className="mrp">
                                          <i className="fa fa-inr"></i>
                                          {price.mrp}
                                        </p>
                                        <p className="selling-price">
                                          <i className="fa fa-inr"></i>
                                          {price.sellingPrice}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-2">
                            {formData?.products?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => {
                                  handleRemoveProduct(product._id);
                                }}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() => {
                                  handleAddProduct(product);
                                }}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Addons Product Modal */}
      <div
        className="modal fade"
        id="addonsProductModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addonsProductModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addonsProductModalLabel">
                Add Ons Product
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <form className="form-horizontal form-material">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Enter product name"
                      onChange={handleSearchProduct}
                    />
                  </div>
                </form>
              </div>

              {/* Search Products */}
              <div className="">
                {addonsProducts.map((product) => {
                  return (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              src={product.image}
                              alt=""
                              className="img img-fluid"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "35px",
                              }}
                            />
                          </div>
                          <div className="col-md-8">
                            <h5 className="m-0 p-0">{product.name}</h5>

                            <p className="p-0 m-0">
                              Price : <i className="fa fa-inr"></i>
                              {product?.sellingPrice}
                            </p>

                            {formData?.adonProducts?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <>
                                {/* Quantity */}
                                <div className="cart-wrapper">
                                  <button
                                    className="minus"
                                    onClick={() => {
                                      handleDecreaseAddonQty(product._id);
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    readOnly
                                    value={getAddonQuantity(product._id)}
                                    className="qty"
                                  />

                                  <button
                                    className="plus"
                                    onClick={() => {
                                      handleIncreaseAddonQty(product._id);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-2">
                            {formData?.adonProducts?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => {
                                  handleRemoveAddonProduct(product._id);
                                }}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() => {
                                  handleAddAddonsProduct(product);
                                }}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Available Coupon Modal */}
      <div
        className="modal fade"
        id="availableCouponModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="availableCouponModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="availableCouponModalLabel">
                Available Coupon
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {coupons.map((coupon, index) => {
                return (
                  <div className="card card-body mb-2" key={index}>
                    <div className="coupon">
                      <span>{coupon.code}</span>
                      <button
                        className=""
                        onClick={() => {
                          navigator.clipboard.writeText(coupon.code);
                          M.toast({
                            html: "Coupon Code Copied",
                            classes: "bg-success",
                          });
                        }}
                      >
                        Copy
                      </button>
                    </div>
                    <div className="mt-2">
                      <h6>Applied For : {coupon.applyFor}</h6>
                      <h6>Minimum Amount : {coupon.minimumAmount}</h6>
                      <h6>
                        OFF :{" "}
                        {coupon.discountType == "PERCENTAGE"
                          ? `${coupon.discount}%`
                          : `Rs ${coupon.discount}/-`}
                      </h6>
                      <p className="mt-2">
                        {ReactHtmlParser(coupon.description || <></>)}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateOrder;
