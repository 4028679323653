import React, { useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import date from "date-and-time";
import Breadcrumb from "../../components/Breadcrumb";

const ProductReportDetails = () => {
  const { id } = useParams();
  const [userLoaded, setUserLoaded] = useState(false);
  const [orders, setOrders] = useState([]);
  //   const [user, setUser] = useState({});
  const [product, setProduct] = useState({
    priceVariants: [],
  });
  const [productLoading, setProductLoading] = useState(true);
  // get Order Details
  useEffect(() => {
    setUserLoaded(false);
    fetch(`${Config.SERVER_URL}/order?productId=${id}&paymentStatus=Credit`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setOrders(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setUserLoaded(true);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setUserLoaded(true);
        }
      );
  }, []);

  // get Product Details
  useEffect(() => {
    setProductLoading(true);
    fetch(`${Config.SERVER_URL}/product/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          console.log(result.body);
          if (result.status === 200) {
            setProduct(result.body);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setProductLoading(false);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setProductLoading(false);
        }
      );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"USER LISTS"} pageTitle={"Update User"} />
        {/* Details */}

        {userLoaded ? (
          <div className={"row"}>
            {/* Left Section */}
            <div className="col-md-12">
              {/* Personal Details */}
              <div className="card shadow-sm border-0">
                <div className="card-body">
                  <div className="d-flex">
                    <div className="py-3">
                      <img
                        style={{
                          height: "80px",
                          width: "80px",
                          borderRadius: "50px",
                        }}
                        src={product.defaultImage}
                        alt=""
                      />
                    </div>

                    <div className="p-2 ml-3 ">
                      <h4>{product.name}</h4>
                      <p>
                        <span className="font-weight-normal">Flavour : </span>
                        <span> {product?.flavour?.name}</span>
                      </p>
                      <p>
                        <span className="font-weight-normal">Shape : </span>
                        <span> {product?.shape?.name}</span>
                      </p>
                      <p>
                        <span className="font-weight-normal">Price : </span>
                        <span>
                          &#8377;{product?.priceVariants[0]?.sellingPrice} to{" "}
                        </span>
                        <span>
                          &#8377;
                          {
                            product?.priceVariants[
                              product?.priceVariants?.length - 1
                            ]?.sellingPrice
                          }
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Order Details */}
              <div className="card shadow-sm border-0 mt-3">
                {userLoaded ? (
                  <div className="card-body">
                    <div className="d-flex justify-content-between">
                      <h4>User Details </h4>
                    </div>
                    <div class="card-body">
                      {orders.length ? (
                        <div class="table-responsive">
                          <table class="table bg-white">
                            <thead>
                              <tr>
                                <th>Order</th>
                                <th>Customer</th>
                                <th>Order Date</th>
                                <th>Status</th>
                                <th>Total</th>
                                <th>Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {orders.map((order, index) => {
                                return (
                                  <tr key={index}>
                                    <td> # {order.orderId} </td>
                                    <td> {order?.customerId?.name} </td>
                                    <td>
                                      {date.format(
                                        new Date(order.createdAt),
                                        "DD-MM-YYYY"
                                      )}
                                    </td>
                                    <td>
                                      <span class="text-info">
                                        {order.orderStatus}
                                      </span>
                                    </td>
                                    <td>
                                      <i class="fa fa-inr"></i>
                                      {order.totalAmount} for
                                      {order.products.length} item
                                    </td>
                                    <td>
                                      <Link
                                        class="btn btn-info"
                                        to={`/branch/user/show/${order?.customerId?._id}`}
                                      >
                                        View
                                      </Link>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="alert alert-danger h6">
                          Order not placed yet.
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className={"bg-white p-3 text-center"}>
                    <span
                      className="spinner-border spinner-border-sm mr-1"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Loading..
                  </div>
                )}
              </div>
              {/* <!-- Button trigger modal --> */}
            </div>
          </div>
        ) : (
          <div className="col-md-12 m-auto">
            <div className={"bg-white p-3 text-center"}>
              <span
                className="spinner-border spinner-border-sm mr-1"
                role="status"
                aria-hidden="true"
              ></span>
              Loading..
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductReportDetails;
