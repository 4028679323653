import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";
import date from "date-and-time";
function EditOrder() {
  const history = useHistory();
  const { id } = useParams();

  const [isAddLoaded, setIsAddLoaded] = useState(true);

  const [shippingMethods, setShippingMethods] = useState([]);
  const [selectedShippingMethod, setSelectedShippingMethod] = useState({});

  const [order, setOrder] = useState({});
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [orderUpdating, setOrderUpdating] = useState(false);

  const [addonProducts, setAddonProducts] = useState([]);
  const [address, setAddress] = useState({
    name: "",
    mobile: "",
    alternateMobile: "",
    address: "",
    landmark: "",
    city: "",
    addressType: "HOME",
    pincode: "",
  });

  const [addonTotalAmount, setAddonTotalAmount] = useState(0);
  const [appliedCoupon, setAppliedCoupon] = useState({
    code: "",
    discount: "",
    discountType: "",
  });
  const [discountWithCoupon, setDiscountWithCoupon] = useState("");
  const [subTotalAmount, setSubTotalAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    const updateOrder = {
      ...order,
    };

    fetch(`${Config.SERVER_URL}/order/${id}`, {
      method: "PUT",
      body: JSON.stringify(updateOrder),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.error);
            errorKeys.forEach((key) => {
              M.toast({ html: result.error[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // Get Oders Details
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/order/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setOrder(result.body);
            setSelectedProducts(result?.body?.products);
            setSelectedAddons(result?.body?.adonProducts);
            setSelectedShippingMethod(result?.body?.shippingMethod);
            setAddress(result?.body?.shippingAddress);

            setTotalAmount(+result?.body?.totalAmount);
            setSubTotalAmount(+result?.body?.subtotal);
            setAddonTotalAmount(+result?.body?.adonTotalAmount);
            setDiscountWithCoupon(+result?.body?.discountWithCoupon);
            if (result?.body?.coupon) setAppliedCoupon(result?.body?.coupon);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // Get Shipping methods
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/shipping-method`, {
      method: "GET", // or 'PUT'
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setShippingMethods(data.body);
        } else {
          console.log(
            "Error Occured While loading shippingMethods : ProductDetails"
          );
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
      });
  }, []);

  // Get Addons Product
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/adon-product?skip=0&limit=40`)
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 200) {
          setAddonProducts(data.body);
        } else {
          console.log(
            "Error Occured While loading Addons Products : AdonProducts"
          );
        }
      })
      .catch((error) => {
        console.error("Header Error:", error);
      });
  }, []);

  // Calculate total amount
  useEffect(() => {
    let products = [...selectedProducts];
    let adonProducts = [...selectedAddons];
    if (products.length) {
      // Products
      let array = products.map(
        (product) => Number(product.price) * Number(product.quantity)
      );
      let sub_total = array.reduce((a, b) => a + b, 0);

      // addon products
      let addonArray = adonProducts.map(
        (product) => Number(product.price) * Number(product.quantity)
      );
      let addon_total = addonArray.reduce((a, b) => a + b, 0);

      // calculate coupon discount amount
      let dis_with_coupon = discountWithCoupon || 0;
      // if (appliedCoupon.code) {
      //   if (appliedCoupon.discountType === "PERCENTAGE") {
      //     dis_with_coupon = (sub_total * appliedCoupon.discount) / 100;
      //   } else {
      //     dis_with_coupon = appliedCoupon.discount;
      //   }
      // }

      // Calculate Total
      let total = 0;
      if (selectedShippingMethod.amount) {
        total =
          sub_total +
          addon_total +
          parseInt(selectedShippingMethod.amount) -
          dis_with_coupon;
      } else {
        total = sub_total + addon_total - dis_with_coupon;
      }

      if (selectedShippingMethod.amount) {
        setSubTotalAmount(sub_total + parseInt(selectedShippingMethod.amount));
      } else {
        setSubTotalAmount(sub_total);
      }
      setAddonTotalAmount(addon_total);
      // setDiscountWithCoupon(dis_with_coupon);
      setTotalAmount(total);
    }
  }, [selectedProducts, selectedAddons, appliedCoupon, selectedShippingMethod]);

  // handleCakeMessage
  const handleCakeMessage = (productId, message) => {
    let allProducts = [...selectedProducts];
    let productIndex = allProducts.findIndex(
      (product) => product.productId == productId
    );

    if (productIndex != -1) {
      allProducts[productIndex].messageOnCake = message;
      setSelectedProducts(allProducts);
    }
  };

  // handleUpdateOrder
  const handleUpdateOrder = async function () {
    setOrderUpdating(true);

    // calculate total amount
    // let totalAmount = +order.totalAmount;
    // if (order?.shippingMethod?.amount != selectedShippingMethod.amount) {
    //   totalAmount = totalAmount - Number(order?.shippingMethod?.amount);
    //   totalAmount = totalAmount + Number(selectedShippingMethod.amount);
    // }

    let data = {
      products: selectedProducts,
      adonProducts: selectedAddons,
      adonTotalAmount: addonTotalAmount,
      totalAmount: totalAmount,
      subtotal: subTotalAmount,
      shippingMethod: selectedShippingMethod,
      shippingAddress: address,
    };

    try {
      const response = await fetch(`${Config.SERVER_URL}/order/${id}`, {
        method: "PUT",
        body: JSON.stringify(data),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      });
      const result = await response.json();
      if (result.status == 200) {
        M.toast({ html: result.message, classes: "bg-success" });
        history.goBack();
      } else {
        const errorKeys = Object.keys(result.error);
        errorKeys.forEach((key) => {
          M.toast({ html: result.error[key], classes: "bg-danger" });
        });
        M.toast({ html: result.message, classes: "bg-danger" });
      }
      setOrderUpdating(false);
    } catch (error) {
      M.toast({ html: error.message, classes: "bg-danger" });
      setOrderUpdating(false);
    }
  };

  // ===========  Product ===================

  // handleSearchProduct
  const handleSearchProduct = async function (event) {
    // event.preventDefault();
    try {
      const response = await fetch(
        `${Config.SERVER_URL}/product/search?query=${event?.target?.value}&limit=20`
      );
      const result = await response.json();
      if (result.status === 200) {
        setProducts(result.body);
      } else {
        M.toast({ html: result.message, classes: "bg-danger" });
      }
    } catch (error) {
      M.toast({ html: error.message, classes: "bg-danger" });
    }
  };

  // make object
  const makeObject = function (product) {
    const productDetails = {};
    productDetails.name = product.name;
    productDetails.slug = product.slug;
    productDetails.parentCategories = product.parentCategories;
    productDetails.categories = product.categories;
    productDetails.productId = product._id;
    productDetails.quantity = 1;
    productDetails.price = "";
    productDetails.mrp = "";
    productDetails.weight = "";
    productDetails.flavour = product.flavour.name;
    productDetails.shape = product.shape.name;
    productDetails.cakeType = product.type.name;
    productDetails.image = product.defaultImage;
    productDetails.images = product.images;
    productDetails.breadType = product.breadType;
    productDetails.messageOnCake = "";
    productDetails.imageOnCake = "";

    return productDetails;
  };

  // handleAddProduct
  const handleAddProduct = function (product) {
    let products = [...selectedProducts];
    let filter = products.filter((p) => p.productId == product._id);
    if (filter.length) {
    } else {
      let productDetails = makeObject(product);
      products.push(productDetails);
    }

    setSelectedProducts(products);
  };

  // handleRemoveProduct
  const handleRemoveProduct = function (productId) {
    let products = [...selectedProducts];
    let filter = products.filter((p) => p.productId != productId);
    setSelectedProducts(filter);
  };

  //  handleWeightNPrice
  const handleWeightNPrice = function (productId, price) {
    let products = [...selectedProducts];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      products[index].price = price.sellingPrice;
      products[index].mrp = price.mrp;
      products[index].weight = price.weight;
      setSelectedProducts(products);
    }
  };

  // getSelectedWeigntNPrice
  const getSelectedWeigntNPrice = function (productId) {
    let products = [...selectedProducts];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return products[index].weight;
    }
    return "";
  };

  // handleDecreaseQty
  const handleDecreaseQty = function (productId) {
    let products = [...selectedProducts];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      if (products[index].quantity > 1) {
        products[index].quantity = parseInt(products[index].quantity) - 1;
        setSelectedProducts(products);
      }
    }
  };
  // handleIncreaseQty
  const handleIncreaseQty = function (productId) {
    let products = [...selectedProducts];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      products[index].quantity = parseInt(products[index].quantity) + 1;
      setSelectedProducts(products);
    }
  };

  // getQuantity
  const getQuantity = function (productId) {
    let products = [...selectedProducts];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return products[index].quantity;
    }
    return 1;
  };

  // ===========  Addond Parts ==============

  // handleAddAddonsProduct
  const handleAddAddonsProduct = function (product) {
    let products = [...selectedAddons];
    let filter = products.filter((p) => p.productId == product._id);
    if (filter.length) {
    } else {
      let productDetails = {
        name: product.name,
        slug: product.slug,
        productId: product._id,
        quantity: 1,
        price: product.sellingPrice,
        image: product.image,
      };
      products.push(productDetails);
    }

    setSelectedAddons(products);
  };

  // handleRemoveAddonProduct
  const handleRemoveAddonProduct = function (productId) {
    let ans = window.confirm("Are you sure you want to remove");
    if (ans) {
      let filter = selectedAddons.filter((p) => p.productId != productId);
      setSelectedAddons(filter);
    }
  };

  // handleDecreaseAddonQty
  const handleDecreaseAddonQty = function (productId) {
    let index = selectedAddons.findIndex((p) => p.productId == productId);
    if (index != -1) {
      if (selectedAddons[index].quantity > 1) {
        let products = [...selectedAddons];

        products[index].quantity = parseInt(products[index].quantity) - 1;
        setSelectedAddons(products);
      }
    }
  };
  // handleIncreaseAddonQty
  const handleIncreaseAddonQty = function (productId) {
    let products = [...selectedAddons];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      products[index].quantity = parseInt(products[index].quantity) + 1;
      setSelectedAddons(products);
    }
  };

  // getAddonQuantity
  const getAddonQuantity = function (productId) {
    let products = [...selectedAddons];
    let index = products.findIndex((p) => p.productId == productId);
    if (index != -1) {
      return products[index].quantity;
    }
    return 1;
  };

  return (
    <>
      <div className="page-wrapper px-0 pt-0">
        <div className={"container-fluid"}>
          {/* Bread crumb and right sidebar toggle */}
          <Breadcrumb title={"EDIT ORDER"} pageTitle={"Update Order"} />
          {/* End Bread crumb and right sidebar toggle */}

          {/* Form */}
          <div className="row mt-2">
            <div className={"col-md-12 mx-auto"}>
              <form
                onSubmit={submitHandler}
                className="form-horizontal form-material"
              >
                {/* ORDER DETAILS */}
                <div className={"row shadow-sm bg-white py-3"}>
                  <div className="col-md-12">
                    <h3 className={"my-3 text-info"}>ORDER DETAILS</h3>
                  </div>

                  {/* CUSTOMER DETAILS */}
                  <div className={"form-group col-md-6"}>
                    <h5 className={"my-3 text-info"}>CUSTOMER DETAILS</h5>
                    <p>
                      <span className="font-weight-bold">NAME :</span>
                      {order?.customerId?.name}
                    </p>
                    <p>
                      <span className="font-weight-bold">MOBILE :</span>
                      {order?.customerId?.mobile}
                    </p>
                  </div>

                  {/* ORDER DETAILS */}
                  <div className={"form-group col-md-6"}>
                    <h5 className={"my-3 text-info"}>ORDER DETAILS</h5>
                    <p>
                      <span className="font-weight-bold">ORDER ID :</span>
                      {order.orderId}
                    </p>
                    <p>
                      <span className="font-weight-bold">AMOUNT :</span>
                      {order?.totalAmount}
                    </p>
                    <p>
                      <span className="font-weight-bold">PRODUCTS : </span>
                      {order?.products?.length}
                    </p>
                  </div>

                  {/* SHIPPING DETAILS */}
                  {/* <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SHIPPING DETAILS !
                  </label>
                  <input
                    type="text"
                    value={order.maximumOrderQuantity}
                    onChange={(evt) =>
                      setOrder({
                        ...order,
                        maximumOrderQuantity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"2"}
                  />
                </div> */}

                  {/* Is Photo Cake*/}
                  {/* <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PHOTO CAKE
                  </label>
                  <div className="d-flex my-3">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="cakeType3"
                        name="photoCake"
                        value={true}
                        checked={
                          order?.isPhotoCake?.toString() == "true"
                            ? true
                            : false
                        }
                        onChange={(evt) =>
                          setOrder({
                            ...order,
                            isPhotoCake: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="cakeType3"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="cakeType4"
                        name="photoCake"
                        checked={
                          order?.isPhotoCake?.toString() == "false"
                            ? true
                            : false
                        }
                        value={false}
                        onChange={(evt) => {
                          setOrder({
                            ...order,
                            isPhotoCake: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="cakeType4"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div> */}
                </div>

                {/* PRODUCTS */}
                <div className="row shadow-sm bg-white mt-3 py-3">
                  <div className="col-md-12 d-flex justify-content-between">
                    <h3 className="text-info">PRODUCTS</h3>
                    <button
                      className="btn btn-info"
                      type="button"
                      data-toggle="modal"
                      data-target="#productModal"
                    >
                      Add Product
                    </button>
                  </div>

                  {selectedProducts.map((product) => {
                    return (
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex gap-2">
                              <img
                                src={product.image}
                                alt=""
                                style={{
                                  height: "140px",
                                  width: "140px",
                                  borderRadius: "20px",
                                }}
                              />
                              <div className="p-3">
                                <h5 className="m-0 p-0">{product.name}</h5>
                                <p className="m-0 p-0">
                                  Flavour : {product?.flavour}
                                </p>
                                <p className="m-0 p-0">
                                  Shape : {product?.shape}
                                </p>

                                <p className="p-0 m-0">
                                  Weight : {product?.weight}
                                </p>

                                <p className="m-0 p-0">
                                  Qty : {product.quantity} X
                                  <i className="fa fa-inr"></i> {product?.price}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="">
                              <label htmlFor="" className="text-dark h6">
                                Message on Cake
                              </label>
                              <input
                                type="text"
                                value={product.messageOnCake}
                                onChange={(evt) => {
                                  handleCakeMessage(
                                    product.productId,
                                    evt.target.value
                                  );
                                }}
                                className="form-control"
                                placeholder="Enter message"
                              />
                            </div>

                            <div className="d-flex align-items-center mt-3">
                              {/* Quantity */}
                              <div className="cart-wrapper">
                                <div className="">
                                  <button
                                    className="minus"
                                    type="button"
                                    onClick={() => {
                                      handleDecreaseQty(product.productId);
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    readOnly
                                    value={product.quantity}
                                    className="qty"
                                  />

                                  <button
                                    className="plus"
                                    type="button"
                                    onClick={() => {
                                      handleIncreaseQty(product.productId);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>

                              {/* Action */}
                              <div className="delete-button">
                                {/* Edit Button */}
                                <button
                                  className="btn btn-info"
                                  type="button"
                                  data-toggle="modal"
                                  data-target="#productModal"
                                  onClick={(event) => {
                                    event.target.value = product.name;
                                    handleSearchProduct(event);
                                  }}
                                >
                                  <i className="fa fa-pencil"></i>
                                </button>

                                {/* Delete Button */}
                                <button
                                  className="btn btn-danger"
                                  type="button"
                                  onClick={() => {
                                    handleRemoveProduct(product.productId);
                                  }}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {/* ADDONS PRODUCTS */}
                <div className="row shadow-sm bg-white mt-3 py-3">
                  <div className="col-md-12 d-flex justify-content-between">
                    <h3 className="text-info">ADDONS PRODUCTS</h3>
                    <button
                      className="btn btn-info"
                      type="button"
                      data-toggle="modal"
                      data-target="#addonsProductModal"
                    >
                      Add Product
                    </button>
                  </div>

                  {selectedAddons.map((product) => {
                    return (
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="d-flex gap-2">
                              <img
                                src={product.image}
                                alt=""
                                style={{
                                  height: "80px",
                                  width: "80px",
                                  borderRadius: "20px",
                                }}
                              />
                              <div className="p-3">
                                <h5 className="m-0 p-0">{product.name}</h5>
                                <p className="m-0 p-0">
                                  {product.quantity} X
                                  <i className="fa fa-inr"></i> {product.price}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex">
                            {/* Quantity */}
                            <div className="cart-wrapper">
                              <div className="">
                                <button
                                  className="minus"
                                  type="button"
                                  onClick={() => {
                                    handleDecreaseAddonQty(product.productId);
                                  }}
                                >
                                  -
                                </button>
                                <input
                                  type="text"
                                  readOnly
                                  value={product.quantity}
                                  className="qty"
                                />

                                <button
                                  className="plus"
                                  type="button"
                                  onClick={() => {
                                    handleIncreaseAddonQty(product.productId);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                            </div>

                            {/* Delete Button */}
                            <div className="delete-button">
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => {
                                  handleRemoveAddonProduct(product.productId);
                                }}
                              >
                                <i className="fa fa-trash"></i>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  {/*  */}
                  <div className="col-md-12">
                    <div className="table-responsive">
                      <table className="table">
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Subtotal</td>
                          <td>{subTotalAmount}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Addon Total Amount</td>
                          <td>{addonTotalAmount || 0}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Coupon Discount ({appliedCoupon.code})</td>
                          <td>{discountWithCoupon || 0}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Delivery Charge</td>
                          <td>{selectedShippingMethod.amount || 0}</td>
                        </tr>
                        <tr>
                          <td></td>
                          <td></td>
                          <td>Total</td>
                          <td>{totalAmount}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>

                {/* SHIPPING METHODS */}
                <div className="row shadow-sm bg-white mt-3 py-3">
                  <div className="col-md-12">
                    <h3 className="text-info">SHIPPING METHODS</h3>
                  </div>
                  <div className="col-md-6">
                    <h6>
                      <span className="font-weight-bold">Method :</span>
                      {selectedShippingMethod?.method}
                    </h6>
                    <h6>
                      <span className="font-weight-bold">Date :</span>
                      {date.format(
                        new Date(selectedShippingMethod?.date),
                        "DD-MM-YYYY"
                      )}
                    </h6>
                    <h6>
                      <span className="font-weight-bold"> Start Time :</span>
                      {selectedShippingMethod?.startTime
                        ? date.transform(
                            selectedShippingMethod?.startTime,
                            "HH:mm",
                            "hh:mm A"
                          )
                        : null}
                    </h6>
                    <h6>
                      <span className="font-weight-bold">End Time :</span>
                      {selectedShippingMethod?.endTime
                        ? date.transform(
                            selectedShippingMethod?.endTime,
                            "HH:mm",
                            "hh:mm A"
                          )
                        : null}
                    </h6>
                  </div>

                  <div className="col-md-6">
                    <div className="row">
                      {/* <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="" className="text-dark h6">
                            Order Date
                          </label>
                          <input
                            type="date"
                            value={date.format(
                              new Date(order.createdAt),
                              "YYYY-MM-DD"
                            )}
                            onChange={(evt) => {
                              setOrder({
                                ...order,
                                createdAt: evt.target.value,
                              });
                            }}
                            className="form-control"
                          />
                        </div>
                      </div> */}
                      <div className="col-md-12">
                        <div className="form-group">
                          <label htmlFor="" className="text-dark h6">
                            Shipping Date
                          </label>
                          <input
                            type="date"
                            value={date.format(
                              new Date(selectedShippingMethod?.date),
                              "YYYY-MM-DD"
                            )}
                            onChange={(evt) => {
                              setSelectedShippingMethod({
                                ...selectedShippingMethod,
                                date: evt.target.value,
                              });
                            }}
                            className="form-control"
                            placeholder="Select Shipping Date"
                          />
                        </div>
                      </div>
                    </div>
                    <div id="accordion">
                      {shippingMethods.map((method, index) => {
                        return (
                          <div className="card" key={index}>
                            <div className="card-header" id="headingOne">
                              <h5 className="mb-0">
                                <h6
                                  className="h6"
                                  style={{ cursor: "pointer" }}
                                  data-toggle="collapse"
                                  data-target={`#collapse-${index}`}
                                  aria-expanded="true"
                                  aria-controls={`collapse-${index}`}
                                >
                                  {`${method.name} Rs ${method.amount}/-`}
                                </h6>
                              </h5>
                            </div>

                            <div
                              id={`collapse-${index}`}
                              className={`collapse ${
                                selectedShippingMethod?.method == method?.name
                                  ? "show"
                                  : ""
                              }`}
                              aria-labelledby={`collapse-${index}`}
                              data-parent="#accordion"
                            >
                              <div className="card-body">
                                {method?.shippingTimes?.map(
                                  (timeDetails, index) => {
                                    console.log(timeDetails._id);
                                    return (
                                      <div
                                        className="custom-control custom-radio pl-0 ml-0 mb-2"
                                        key={timeDetails._id}
                                      >
                                        <input
                                          type="radio"
                                          id={`time-${timeDetails._id}`}
                                          name="shippingTime"
                                          value={true}
                                          checked={
                                            selectedShippingMethod?.method ==
                                              method?.name &&
                                            selectedShippingMethod?.startTime ==
                                              timeDetails?.startTime
                                              ? true
                                              : false
                                          }
                                          onChange={(evt) =>
                                            setSelectedShippingMethod({
                                              ...selectedShippingMethod,
                                              method: method.name,
                                              amount: method.amount,
                                              startTime: timeDetails.startTime,
                                              endTime: timeDetails.endTime,
                                            })
                                          }
                                          className="custom-control-input"
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`time-${timeDetails._id}`}
                                        >
                                          {date.transform(
                                            timeDetails.startTime,
                                            "HH:mm",
                                            "hh:mm A"
                                          )}
                                          -
                                          {date.transform(
                                            timeDetails.endTime,
                                            "HH:mm",
                                            "hh:mm A"
                                          )}
                                        </label>
                                      </div>
                                    );
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>

                {/* DELIVERY ADDRESS */}
                <div className="row shadow-sm bg-white mt-3 py-3">
                  <div className="col-md-12 mt-3">
                    <h4 className="text-info">DELIVERY ADDRESS</h4>
                    <form className="form-horizontal form-material">
                      <div className="row">
                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            Name
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Name"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                name: evt.target.value,
                              })
                            }
                            value={address.name}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            Mobile
                          </label>
                          <input
                            type="tel"
                            placeholder="Enter Mobile"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                mobile: evt.target.value,
                              })
                            }
                            value={address.mobile}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            Alternative Mobile
                          </label>
                          <input
                            type="tel"
                            placeholder="Enter Mobile"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                alternateMobile: evt.target.value,
                              })
                            }
                            value={address.alternateMobile}
                          />
                        </div>

                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            Address
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Address"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                address: evt.target.value,
                              })
                            }
                            value={address.address}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            Landmark
                          </label>
                          <input
                            type="text"
                            placeholder="Enter Landmark"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                landmark: evt.target.value,
                              })
                            }
                            value={address.landmark}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            City
                          </label>
                          <input
                            type="text"
                            placeholder="Enter City"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                city: evt.target.value,
                              })
                            }
                            value={address.city}
                          />
                        </div>
                        <div className="form-group col-md-4">
                          <label htmlFor="" className="text-dark h6 active">
                            Pincode
                          </label>
                          <input
                            type="tel"
                            placeholder="Enter Pincode"
                            className="form-control"
                            onChange={(evt) =>
                              setAddress({
                                ...address,
                                pincode: evt.target.value,
                              })
                            }
                            value={address.pincode}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className={"form-group col-md-12 mt-3"}>
                    <button
                      className="btn btn-info rounded px-3 py-2"
                      type={"button"}
                      onClick={handleUpdateOrder}
                    >
                      {!false ? (
                        <div>
                          <i className="fas fa-plus"></i> Update Order
                        </div>
                      ) : (
                        <div>
                          <span
                            className="spinner-border spinner-border-sm mr-1"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          Loading..
                        </div>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* ================== Modal Section ================= */}
      {/* Product Modal */}
      <div
        className="modal fade"
        id="productModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="productModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="productModalLabel">
                Find Product
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <form className="form-horizontal form-material">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Enter product name"
                      onChange={handleSearchProduct}
                    />
                  </div>
                </form>
              </div>

              {/* Search Products */}
              <div className="">
                {products.map((product) => {
                  return (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              src={product.defaultImage}
                              alt=""
                              className="img img-fluid"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "35px",
                              }}
                            />
                          </div>
                          <div className="col-md-8">
                            <h5 className="m-0 p-0">{product.name}</h5>
                            <p className="p-0 m-0">
                              Flavour : {product?.flavour?.name}
                            </p>
                            <p className="p-0 m-0">
                              Shape : {product?.shape?.name}
                            </p>

                            <p className="p-0 m-0">
                              Price : <i className="fa fa-inr"></i>
                              {product?.priceVariants[0]?.sellingPrice}
                            </p>

                            {selectedProducts?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <>
                                {/* Cake Message */}
                                <div className="form-group">
                                  <input
                                    type="text"
                                    className="form-control cake-message"
                                    placeholder="Enter message on cake"
                                    // value={getMessageOnCake(product._id)}
                                    // onChange={(evt) => {
                                    //   handleUpdateCakeMessage(
                                    //     product._id,
                                    //     evt.target.value
                                    //   );
                                    // }}
                                  />
                                </div>

                                {/* Quantity */}
                                <div className="cart-wrapper">
                                  <button
                                    className="minus"
                                    onClick={() => {
                                      handleDecreaseQty(product._id);
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    readOnly
                                    value={getQuantity(product._id)}
                                    className="qty"
                                  />

                                  <button
                                    className="plus"
                                    onClick={() => {
                                      handleIncreaseQty(product._id);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>

                                {/* Price */}
                                <div className="d-flex">
                                  {product?.priceVariants?.map((price) => {
                                    return (
                                      <div
                                        className={`m-2 price-wrapper ${
                                          getSelectedWeigntNPrice(
                                            product._id
                                          ) == price.weight
                                            ? "bg-warning"
                                            : null
                                        }`}
                                        onClick={() => {
                                          handleWeightNPrice(
                                            product._id,
                                            price
                                          );
                                        }}
                                      >
                                        <p className="weight">{price.weight}</p>
                                        <p className="mrp">
                                          <i className="fa fa-inr"></i>
                                          {price.mrp}
                                        </p>
                                        <p className="selling-price">
                                          <i className="fa fa-inr"></i>
                                          {price.sellingPrice}
                                        </p>
                                      </div>
                                    );
                                  })}
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-2">
                            {selectedProducts?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => {
                                  handleRemoveProduct(product._id);
                                }}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() => {
                                  handleAddProduct(product);
                                }}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Addons Product Modal */}
      <div
        className="modal fade"
        id="addonsProductModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="addonsProductModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-xl" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="addonsProductModalLabel">
                Add Ons Product
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="">
                <form className="form-horizontal form-material">
                  <div className="form-group">
                    <input
                      type="search"
                      className="form-control"
                      placeholder="Enter product name"
                      // onChange={handleSearchProduct}
                    />
                  </div>
                </form>
              </div>

              {/* Search Products */}
              <div className="">
                {addonProducts.map((product) => {
                  return (
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-2">
                            <img
                              src={product.image}
                              alt=""
                              className="img img-fluid"
                              style={{
                                height: "70px",
                                width: "70px",
                                borderRadius: "35px",
                              }}
                            />
                          </div>
                          <div className="col-md-8">
                            <h5 className="m-0 p-0">{product.name}</h5>

                            <p className="p-0 m-0">
                              Price : <i className="fa fa-inr"></i>
                              {product?.sellingPrice}
                            </p>

                            {selectedAddons?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <>
                                {/* Quantity */}
                                <div className="cart-wrapper">
                                  <button
                                    className="minus"
                                    onClick={() => {
                                      handleDecreaseAddonQty(product._id);
                                    }}
                                  >
                                    -
                                  </button>
                                  <input
                                    type="text"
                                    readOnly
                                    value={getAddonQuantity(product._id)}
                                    className="qty"
                                  />

                                  <button
                                    className="plus"
                                    onClick={() => {
                                      handleIncreaseAddonQty(product._id);
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </>
                            ) : null}
                          </div>
                          <div className="col-md-2">
                            {selectedAddons?.some(
                              (p) => p.productId == product._id
                            ) ? (
                              <button
                                className="btn btn-danger"
                                type="button"
                                onClick={() => {
                                  handleRemoveAddonProduct(product._id);
                                }}
                              >
                                Remove
                              </button>
                            ) : (
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() => {
                                  handleAddAddonsProduct(product);
                                }}
                              >
                                Add
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-dismiss="modal"
              >
                Close
              </button>
              {/* <button type="button" className="btn btn-primary">
                Save changes
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default EditOrder;
