import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import CKEditor from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import M from "materialize-css";
import Config from "../../../config/Config";
import Breadcrumb from "../../components/Breadcrumb";

const AddBill = () => {
  const history = useHistory();
  const [isAddLoaded, setIsAddLoaded] = useState(true);

  const [totalAmount, setTotalAmount] = useState(0);
  const [pendingPayment, setPendingPayment] = useState(0);

  const [formData, setFormData] = useState({
    customerName: "",
    date: "",
    orderDate: "",
    pound: "",
    flavour: "",
    shippingCharges: "",
    customAddons: "",
    advancePayment: "",
    deliveryDate: "",
    deliveryTime: "",
  });

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    fetch(Config.SERVER_URL + "/bills", {
      method: "POST",
      body: JSON.stringify({ ...formData, totalAmount, pendingPayment }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.error);
            errorKeys.forEach((key) => {
              M.toast({ html: result.error[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // calculate amount
  useEffect(() => {
    let ta = totalAmount;
    let pa = pendingPayment;
    if (formData.quantity && formData.price) {
      ta = parseInt(formData.quantity) * parseInt(formData.price);
    }

    if (formData.customAddons) {
      ta = parseInt(ta) + parseInt(formData.customAddons);
    }

    if (formData.shippingCharges) {
      ta = parseInt(ta) + parseInt(formData.shippingCharges);
    }

    if (formData.advancePayment) {
      pa = parseInt(ta) - parseInt(formData.advancePayment);
    }

    setTotalAmount(ta);
    setPendingPayment(pa);
  }, [formData]);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb title={"BILLS"} pageTitle={"Add Bill"} />

        {/* Add Bill Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Bill Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>Bill Details</h3>
                </div>

                {/* CUSTOMER NAME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CUSTOMER NAME !
                  </label>
                  <input
                    type="text"
                    value={formData.customerName}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        customerName: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter customer name"}
                  />
                </div>

                {/* CUSTOMER ADDRESS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CUSTOMER ADDRESS !
                  </label>
                  <input
                    type="text"
                    value={formData.customerAddress}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        customerAddress: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter address"}
                  />
                </div>

                {/* CUSTOMER CITY */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CUSTOMER CITY !
                  </label>
                  <input
                    type="text"
                    value={formData.customerCity}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        customerCity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter city name"}
                  />
                </div>

                {/* CUSTOMER MOBILE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CUSTOMER MOBILE !
                  </label>
                  <input
                    type="text"
                    value={formData.customerMobile}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        customerMobile: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter mobile number"}
                  />
                </div>

                {/* PRODUCT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT !
                  </label>
                  <input
                    type="text"
                    value={formData.product}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        product: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"Enter product"}
                  />
                </div>

                {/* POUND */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    POUND
                  </label>
                  <input
                    type="number"
                    value={formData.pound}
                    onChange={(evt) =>
                      setFormData({ ...formData, pound: evt.target.value })
                    }
                    name={"pound"}
                    className="form-control"
                    placeholder={"Pound"}
                  />
                </div>

                {/* PRODUCT PRICE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT PRICE
                  </label>
                  <input
                    type="number"
                    value={formData.price}
                    onChange={(evt) =>
                      setFormData({ ...formData, price: evt.target.value })
                    }
                    name={"price"}
                    className="form-control"
                    placeholder={"Price"}
                  />
                </div>

                {/* QUANTITY */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    QUANTITY
                  </label>
                  <input
                    type="number"
                    value={formData.quantity}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        quantity: evt.target.value,
                      });
                    }}
                    name={"quantity"}
                    className="form-control"
                    placeholder={"Enter Quantity"}
                  />
                </div>

                {/* DATE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DATE !
                  </label>
                  <input
                    type="date"
                    value={formData.date}
                    onChange={(evt) =>
                      setFormData({ ...formData, date: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Date"}
                  />
                </div>

                {/* ORDER DATE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    ORDER DATE !
                  </label>
                  <input
                    type="date"
                    value={formData.orderDate}
                    onChange={(evt) =>
                      setFormData({ ...formData, orderDate: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Order Date"}
                  />
                </div>

                {/* FLAVOUR */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FLAVOUR
                  </label>
                  <input
                    type="text"
                    value={formData.flavour}
                    onChange={(evt) =>
                      setFormData({ ...formData, flavour: evt.target.value })
                    }
                    name={"flavour"}
                    className="form-control"
                    placeholder={"Flavour"}
                  />
                </div>

                {/* SHIPPING CHARGES */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SHIPPING CHARGES
                  </label>
                  <input
                    type="text"
                    value={formData.shippingCharges}
                    onChange={(evt) => {
                      let totalAmount = parseInt(formData.totalAmount);
                      totalAmount = parseInt(
                        parseInt(evt.target.value) + totalAmount
                      );
                      setFormData({
                        ...formData,
                        shippingCharges: evt.target.value,
                        totalAmount,
                      });
                    }}
                    name={"shippingCharges"}
                    className="form-control"
                    placeholder={"Enter shipping charges"}
                  />
                </div>

                {/* CUSTOM ADDONS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CUSTOM ADDONS
                  </label>
                  <input
                    type="text"
                    value={formData.customAddons}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        customAddons: evt.target.value,
                      });
                    }}
                    name={"customAddons"}
                    className="form-control"
                    placeholder={"Enter addons amount"}
                  />
                </div>

                {/* ADVANCE PAYMENT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    ADVANCE PAYMENT
                  </label>
                  <input
                    type="text"
                    value={formData.advancePayment}
                    onChange={(evt) => {
                      setFormData({
                        ...formData,
                        advancePayment: evt.target.value,
                      });
                    }}
                    name={"advancePayment"}
                    className="form-control"
                    placeholder={"Enter advance payment"}
                  />
                </div>

                {/* PENDING AMOUNT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PENDING AMOUNT
                  </label>
                  <input
                    type="text"
                    value={pendingPayment}
                    onChange={(evt) => setPendingPayment(evt.target.value)}
                    name={"pendingPayment"}
                    className="form-control"
                    placeholder={"Enter pending payment"}
                  />
                </div>

                {/* TOTAL AMOUNT */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    TOTAL AMOUNT
                  </label>
                  <input
                    type="text"
                    value={totalAmount}
                    onChange={(evt) => setTotalAmount(evt.target.value)}
                    name={"totalAmount"}
                    className="form-control"
                    placeholder={"Enter total amount"}
                  />
                </div>

                {/* DELIVERY DATE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DELIVERY DATE
                  </label>
                  <input
                    type="date"
                    value={formData.deliveryDate}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        deliveryDate: evt.target.value,
                      })
                    }
                    name={"deliveryDate"}
                    className="form-control"
                    placeholder={"Delivery date"}
                  />
                </div>

                {/* DELIVERY TIME */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DELIVERY TIME
                  </label>
                  <input
                    type="text"
                    value={formData.deliveryTime}
                    onChange={(evt) =>
                      setFormData({
                        ...formData,
                        deliveryTime: evt.target.value,
                      })
                    }
                    name={"deliveryTime"}
                    className="form-control"
                    placeholder={"Delivery time"}
                  />
                </div>

                <div className={"form-group col-md-12"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isAddLoaded ? (
                      <div>
                        <i className="fas fa-plus"></i> Add Coupon
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddBill;
