import React, { useContext, useState, useEffect } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";
import Config from "../../config/Config";
import date from "date-and-time";
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  PieChart,
  Pie,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { getNextDay, getPreviousDay } from "../helpers";

function Dashboard() {
  // Top 10 Selling Products
  const [topSellingProducts, setTopSellingProducs] = useState([]);
  const [topSellingProductsLoaded, setTopSellingProductsLoaded] =
    useState(false);

  // Top 10 Cusomers
  const [topCustomers, setTopCustomers] = useState([]);
  const [topCustomersLoaded, setTopCustomersLoaded] = useState(false);

  // Total Offline Orders
  const [totalOfflineOrdersLoaded, setTotalOfflineOrdersLoaded] =
    useState(false);
  const [totalOfflineOrders, setTotalOfflineOrders] = useState(0);

  // Total Online Orders
  const [totalOnlineOrdersLoaded, setTotalOnlineOrdersLoaded] = useState(false);
  const [totalOnlineOrders, setTotalOnlineOrders] = useState(0);

  // Total Orders
  const [totalOrdersLoaded, setTotalOrdersLoaded] = useState(false);
  const [totalOrders, setTotalOrders] = useState(0);

  // Today Delivery Order
  const [todayDeliveryOrdersLoaded, setTodayDeliveryOrdersLoaded] =
    useState(false);
  const [todayDeliveryOrders, setTodayDeliveryOrders] = useState(0);

  // Tommorow Delivery Order
  const [tommorowDeliveryOrdersLoaded, setTommorowDeliveryOrdersLoaded] =
    useState(false);
  const [tommorowDeliveryOrders, setTommorowDeliveryOrders] = useState(0);

  // Total False Orders
  const [totalFalseOrdersLoaded, setTotalFalseOrdersLoaded] = useState(false);
  const [totalFalseOrders, setTotalFalseOrders] = useState(0);

  // Today False Orders
  const [todayFalseOrdersLoaded, setTodayFalseOrdersLoaded] = useState(false);
  const [todayFalseOrders, setTodayFalseOrders] = useState(0);

  // Total Checkout Orders
  const [todayCheckoutOrdersLoaded, setTodayCheckoutOrdersLoaded] =
    useState(false);
  const [todayCheckoutOrders, setTodayCheckoutOrders] = useState(0);

  // Today Checkout Orders
  const [totalCheckoutOrdersLoaded, setTotalCheckoutOrdersLoaded] =
    useState(false);
  const [totalCheckoutOrders, setTotalCheckoutOrders] = useState(0);

  // Today Orders
  const [todayOrdersLoaded, setTodayOrdersLoaded] = useState(false);
  const [todayOrders, setTodayOrders] = useState(0);

  // Today Online Orders
  const [todayOnlineOrdersLoaded, setTodayOnlineOrdersLoaded] = useState(false);
  const [todayOnlineOrders, setTodayOnlineOrders] = useState(0);

  // Today Offline Orders
  const [todayOfflineOrdersLoaded, setTodayOfflineOrdersLoaded] =
    useState(false);
  const [todayOfflineOrders, setTodayOfflineOrders] = useState(0);

  // All Users
  const [totalUsersLoaded, setTotalUsersLoaded] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);

  // All Customers
  const [totalCustomersLoaded, setTotalCustomersLoaded] = useState(false);
  const [totalCustomers, setTotalCustomers] = useState(0);

  // State for filters
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  // Getting Today Order data
  useEffect(() => {
    fetch(
      `${Config.SERVER_URL}/order/count?startDate=${date.format(
        getPreviousDay(),
        "YYYY-MM-DD"
      )}&paymentStatus=Credit`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTodayOrdersLoaded(true);
          if (result.status == 200) {
            setTodayOrders(result.body || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTodayOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Today Offline Order data
  useEffect(() => {
    fetch(
      `${Config.SERVER_URL}/order/count?startDate=${date.format(
        getPreviousDay(),
        "YYYY-MM-DD"
      )}&paymentStatus=Credit&orderType=OFFLINE`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTodayOfflineOrdersLoaded(true);
          if (result.status == 200) {
            let body = result?.body?.toString().padStart(2, "0");
            setTodayOfflineOrders(body || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTodayOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Today Online Order data
  useEffect(() => {
    fetch(
      `${Config.SERVER_URL}/order/count?startDate=${date.format(
        getPreviousDay(),
        "YYYY-MM-DD"
      )}&paymentStatus=Credit&orderType=ONLINE`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTodayOnlineOrdersLoaded(true);
          if (result.status == 200) {
            let body = result?.body?.toString()?.padStart(2, "0");
            setTodayOnlineOrders(body || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTodayOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Today Delivery Order
  useEffect(() => {
    fetch(
      `${Config.SERVER_URL}/order?deliveryDate=${date.format(
        new Date(),
        "YYYY-MM-DD"
      )}&paymentStatus=Credit&limit=0`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTodayDeliveryOrdersLoaded(true);
          if (result.status == 200) {
            setTodayDeliveryOrders(result?.body?.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTodayDeliveryOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Tommorow Delivery Order
  useEffect(() => {
    fetch(
      `${Config.SERVER_URL}/order?deliveryDate=${date.format(
        getNextDay(),
        "YYYY-MM-DD"
      )}&paymentStatus=Credit&limit=0`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTommorowDeliveryOrdersLoaded(true);
          if (result.status == 200) {
            setTommorowDeliveryOrders(result?.body?.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTommorowDeliveryOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Total Order data
  useEffect(() => {
    fetch(Config.SERVER_URL + "/order/count?&paymentStatus=Credit", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalOrdersLoaded(true);
          if (result.status == 200) {
            setTotalOrders(result.body || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Total Online Order data
  useEffect(() => {
    fetch(
      Config.SERVER_URL + "/order/count?&paymentStatus=Credit&orderType=ONLINE",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalOnlineOrdersLoaded(true);
          if (result.status == 200) {
            let total = result?.body?.toString()?.padStart(2, 0);
            setTotalOnlineOrders(total || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalOnlineOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Total Offline Order data
  useEffect(() => {
    fetch(
      Config.SERVER_URL +
        "/order/count?&paymentStatus=Credit&orderType=OFFLINE",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalOfflineOrdersLoaded(true);
          if (result.status == 200) {
            let total = result?.body?.toString()?.padStart(2, 0);
            setTotalOfflineOrders(total || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalOfflineOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Total False Order data
  useEffect(() => {
    fetch(Config.SERVER_URL + "/order?limit=0&falseOrder=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalFalseOrdersLoaded(true);
          if (result.status == 200) {
            setTotalFalseOrders(result.body.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalFalseOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Today False Order data
  useEffect(() => {
    let yesterday = date.format(getPreviousDay(), "YYYY-MM-DD");
    fetch(
      Config.SERVER_URL +
        `/order?limit=0&falseOrder=true&startDate=${yesterday}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTodayFalseOrdersLoaded(true);
          if (result.status == 200) {
            setTodayFalseOrders(result.body.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTodayFalseOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Today Checkout Order data
  useEffect(() => {
    const newDate = getPreviousDay();
    let yesterday = date.format(newDate, "YYYY-MM-DD");
    // let yesterday = "2023-09-05";
    fetch(
      Config.SERVER_URL + `/order-checkouts?limit=0&startDate=${yesterday}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTodayCheckoutOrdersLoaded(true);
          if (result.status == 200) {
            setTodayCheckoutOrders(result.body.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTodayCheckoutOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Total Checkout Order data
  useEffect(() => {
    fetch(Config.SERVER_URL + `/order-checkouts?limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalCheckoutOrdersLoaded(true);
          if (result.status == 200) {
            setTotalCheckoutOrders(result.body.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalCheckoutOrdersLoaded(true);
        }
      );
  }, []);

  // Getting Total Users data
  useEffect(() => {
    fetch(Config.SERVER_URL + "/customer?limit=0", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalUsersLoaded(true);
          if (result.status == 200) {
            setTotalUsers(result.body.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalUsersLoaded(true);
        }
      );
  }, []);

  // Getting Total Customers data
  useEffect(() => {
    fetch(Config.SERVER_URL + "/customer/report?allCustomers=true", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          setTotalCustomersLoaded(true);
          if (result.status == 200) {
            setTotalCustomers(result.body.length || 0);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTotalCustomersLoaded(true);
        }
      );
  }, []);

  // Generate Report For Top 10 Selling Products
  useEffect(() => {
    setTopSellingProductsLoaded(false);
    fetch(
      `${Config.SERVER_URL}/order/report?topProducts=true&startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTopSellingProductsLoaded(true);
          if (result.status == 200) {
            setTopSellingProducs(result.body);
            // topSellingProducts = [...result.body];
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTopSellingProductsLoaded(true);
        }
      );
  }, [startDate, endDate]);

  // Generate Report For Top 10 Customers
  useEffect(() => {
    setTopCustomersLoaded(false);
    fetch(
      `${Config.SERVER_URL}/order/report?topCustomers=true&startDate=${startDate}&endDate=${endDate}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
        },
      }
    )
      .then((res) => res.json())
      .then(
        (result) => {
          setTopCustomersLoaded(true);
          if (result.status == 200) {
            setTopCustomers(result.body);
            // topSellingProducts = [...result.body];
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setTopCustomersLoaded(true);
        }
      );
  }, [startDate, endDate]);

  return (
    <div>
      <div className="page-wrapper px-0 pt-0">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Container fluid  --> */}
        {/* <!-- ============================================================== --> */}
        <div className="container-fluid">
          {/* <!-- ============================================================== --> */}
          {/* <!-- Bread crumb and right siLine toggle --> */}
          {/* <!-- ============================================================== --> */}
          <div className="row page-titles mb-0">
            <div className="col-md-5 col-8 align-self-center">
              <h3 className="text-themecolor">Dashboard</h3>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Home</a>
                </li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ol>
            </div>
          </div>
          {/* <!-- End Bread crumb and right sidebar toggle --> */}

          {/* <!-- Card Section --> */}
          <div
            className={"row page-titles px-1 my-0 shadow-none"}
            style={{ background: "none" }}
          >
            <div className="col-md-12">
              <div className="d-flex justify-content-between">
                <h3 className="card-title mb-4">Stats Overview</h3>
              </div>
            </div>

            {/* Card Design */}
            <div className={"col-md-12"}>
              <div className={"row"}>
                {/* Total Users */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/branch/users"}>
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i
                            className={
                              "mdi mdi-account-multiple v-big-icon text-info"
                            }
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalUsersLoaded ? (
                              totalUsers || 0
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-info h6"}>Total Users</span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Total Customers */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/branch/customers"}>
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i
                            className={
                              "mdi mdi-account-circle v-big-icon text-info"
                            }
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalCustomersLoaded ? (
                              totalCustomers || 0
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-info h6"}>
                            Total Customers
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Total Orders */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/branch/orders"}>
                      <div className={"card-body py-0"}>
                        <div
                          className={
                            "d-flex justify-content-between align-items-center"
                          }
                        >
                          <h2 className={"text-info h6"}>Total Orders</h2>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "25px" }}
                          >
                            {totalOrdersLoaded ? (
                              totalOrders || 0
                            ) : (
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </h2>
                        </div>
                        <div className={"d-flex justify-content-between"}>
                          <i className={"mdi mdi-truck v-big-icon text-info"} />
                          <div className="">
                            <p className="p-0 m-0 text-muted font-weight-normal">
                              Online :{" "}
                              {totalOnlineOrdersLoaded ? (
                                totalOnlineOrders || 0
                              ) : (
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </p>
                            <p className="p-0 m-0 text-muted font-weight-normal">
                              Offline :{" "}
                              {totalOfflineOrdersLoaded ? (
                                totalOfflineOrders || 0
                              ) : (
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Total False Orders */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/branch/falseOrders"}>
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i className={"mdi mdi-bus v-big-icon text-info"} />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalFalseOrdersLoaded ? (
                              totalFalseOrders
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-info h6"}>
                            Total False Orders
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Total Checkouts */}
                <div className={"col-md-3"}>
                  <div className={"card bg-white border-0"}>
                    <Link to={"/branch/checkoutOrders"}>
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i className={"mdi mdi-truck v-big-icon text-info"} />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-info"}
                            style={{ fontSize: "30px" }}
                          >
                            {totalCheckoutOrdersLoaded ? (
                              totalCheckoutOrders || 0
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-info h6"}>
                            Total Checkouts
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Today Orders */}
                <div className={"col-md-3"}>
                  <div className={"card bg-info border-0"}>
                    <Link to={"/branch/newOrders"}>
                      <div className={"card-body py-0"}>
                        <div
                          className={
                            "d-flex justify-content-between align-items-center"
                          }
                        >
                          <h2 className={"text-white h6"}>Today Orders</h2>
                          <h2
                            className={"text-white"}
                            style={{ fontSize: "25px" }}
                          >
                            {todayOrdersLoaded ? (
                              todayOrders || 0
                            ) : (
                              <span
                                className="spinner-border spinner-border-sm mr-1"
                                role="status"
                                aria-hidden="true"
                              ></span>
                            )}
                          </h2>
                        </div>
                        <div className={"d-flex justify-content-between"}>
                          <i
                            className={"mdi mdi-calendar v-big-icon text-white"}
                          />
                          <div className="">
                            <p className="p-0 m-0 text-light font-weight-normal">
                              Online :{" "}
                              {todayOnlineOrdersLoaded ? (
                                todayOnlineOrders || 0
                              ) : (
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </p>
                            <p className="p-0 m-0 text-light font-weight-normal">
                              Offline :{" "}
                              {todayOfflineOrdersLoaded ? (
                                todayOfflineOrders || 0
                              ) : (
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Today False Orders */}
                <div className={"col-md-3"}>
                  <div className={"card bg-info border-0"}>
                    <Link
                      to={`/branch/falseOrders?startDate=${date.format(
                        getPreviousDay(),
                        "YYYY-MM-DD"
                      )}`}
                    >
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i
                            className={"mdi mdi-calendar v-big-icon text-light"}
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-light"}
                            style={{ fontSize: "30px" }}
                          >
                            {todayFalseOrdersLoaded ? (
                              todayFalseOrders
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-light h6"}>
                            Today False Orders
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Today Checkout Orders */}
                <div className={"col-md-3"}>
                  <div className={"card bg-info border-0"}>
                    <Link
                      to={`/branch/checkoutOrders?startDate=${date.format(
                        getPreviousDay(),
                        "YYYY-MM-DD"
                      )}`}
                    >
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i
                            className={"mdi mdi-calendar v-big-icon text-light"}
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-light"}
                            style={{ fontSize: "30px" }}
                          >
                            {todayCheckoutOrdersLoaded ? (
                              todayCheckoutOrders
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-light h6"}>
                            Today Checkout Orders
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Today Delivery */}
                <div className={"col-md-3"}>
                  <div className={"card bg-info border-0"}>
                    <Link
                      to={`/branch/orders?deliveryDate=${date.format(
                        new Date(),
                        "YYYY-MM-DD"
                      )}`}
                    >
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i
                            className={"mdi mdi-calendar v-big-icon text-light"}
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-light"}
                            style={{ fontSize: "30px" }}
                          >
                            {todayDeliveryOrdersLoaded ? (
                              todayDeliveryOrders || 0
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-light h6"}>
                            Today Delivery
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>

                {/* Tommorow Delivery */}
                <div className={"col-md-3"}>
                  <div className={"card bg-danger border-0"}>
                    <Link
                      to={`/branch/orders?deliveryDate=${date.format(
                        getNextDay(),
                        "YYYY-MM-DD"
                      )}`}
                    >
                      <div className={"card-body py-2"}>
                        <div className={"float-left"}>
                          <i
                            className={"mdi mdi-calendar v-big-icon text-light"}
                          />
                        </div>
                        <div className={"float-right text-right m-2"}>
                          <h2
                            className={"text-light"}
                            style={{ fontSize: "30px" }}
                          >
                            {tommorowDeliveryOrdersLoaded ? (
                              tommorowDeliveryOrders || 0
                            ) : (
                              <div className={"text-center"}>
                                <span
                                  className="spinner-border spinner-border-sm mr-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              </div>
                            )}
                          </h2>
                          <span className={"text-light h6"}>
                            Tommorow Delivery
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>

            <div className={"col-md-12"}>
              <div className={"row"}>
                <div className="col-md-12">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title mb-4">Graph Overview</h3>
                    <div className="d-flex">
                      <div className="">
                        <input
                          type="date"
                          onChange={(evt) => {
                            setStartDate(evt.target.value);
                          }}
                          className="form-control px-2"
                        />
                      </div>
                      <div className="pl-2">
                        <input
                          type="date"
                          onChange={(evt) => {
                            setEndDate(evt.target.value);
                          }}
                          className="form-control px-2"
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {/* Top 10 Selling Products */}
                <div className={"col-md-6"}>
                  <div className={"card border-0"}>
                    <div className={"card-body py-2"}>
                      <div className="d-flex justify-content-between py-2">
                        <h4 className="text-bold">Top 10 Selling Products</h4>
                        <Link
                          to={"/branch/report/products"}
                          className={"text-info"}
                        >
                          View Report
                        </Link>
                      </div>
                    </div>

                    {topSellingProductsLoaded ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart width={400} height={400}>
                          <Pie
                            dataKey="value"
                            isAnimationActive={false}
                            data={topSellingProducts}
                            cx="50%"
                            cy="50%"
                            outerRadius={80}
                            fill="#C70039"
                            label
                          />

                          <Tooltip />
                        </PieChart>
                      </ResponsiveContainer>
                    ) : (
                      <div
                        className={"text-center"}
                        style={{ height: "300px", paddingTop: "150px" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Top 10 Customers */}
                <div className={"col-md-6"}>
                  <div className={"card border-0"}>
                    <div className={"card-body py-2"}>
                      <div className="d-flex justify-content-between py-2">
                        <h4 className="text-bold">Top 10 Customers</h4>
                        <Link
                          to={"/branch/report/customers"}
                          className={"text-info"}
                        >
                          View Report
                        </Link>
                      </div>
                    </div>

                    {topCustomersLoaded ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          width={500}
                          height={300}
                          data={topCustomers}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Bar dataKey="value" fill="#8884d8" />
                          {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
                        </BarChart>
                      </ResponsiveContainer>
                    ) : (
                      <div
                        className={"text-center"}
                        style={{ height: "300px", paddingTop: "150px" }}
                      >
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                  </div>
                </div>

                {/* Top 10 Parent Category */}
                {/* <div className={"col-md-6"}>
                  <div className={"card border-0"}>
                    <div className={"card-body py-2"}>
                      <div className="d-flex justify-content-between py-2">
                        <h4 className="text-bold">Top 10 Category</h4>
                        <Link to={""} className={"text-info"}>
                          View Report
                        </Link>
                      </div>
                    </div>

                    {topCustomersLoaded ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          width={500}
                          height={300}
                          data={topCustomers}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Legend />
                          <Line
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            activeDot={{ r: 8 }}
                          />
                        </LineChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className={"text-center"}>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                  </div>
                </div> */}

                {/* Top 10 Child Category */}
                {/* <div className={"col-md-6"}>
                  <div className={"card border-0"}>
                    <div className={"card-body py-2"}>
                      <div className="d-flex justify-content-between py-2">
                        <h4 className="text-bold">Top 10 Child Category</h4>
                        <Link to={""} className={"text-info"}>
                          View Report
                        </Link>
                      </div>
                    </div>

                    {topCustomersLoaded ? (
                      <ResponsiveContainer width="100%" height={300}>
                        <AreaChart
                          width={500}
                          height={400}
                          data={topCustomers}
                          margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0,
                          }}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis dataKey="name" />
                          <YAxis />
                          <Tooltip />
                          <Area
                            type="monotone"
                            dataKey="value"
                            stroke="#8884d8"
                            fill="#8884d8"
                          />
                        </AreaChart>
                      </ResponsiveContainer>
                    ) : (
                      <div className={"text-center"}>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                      </div>
                    )}
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* <!-- Row --> */}
          {/* .............. */}
          {/* <!-- ============================================================== --> */}
        </div>
        {/* <!-- ============================================================== --> */}
        {/* <!-- End Container fluid  --> */}
        {/* <!-- footer --> */}
        {/* <!-- ============================================================== --> */}
        <footer className="footer">© 2021 Esta Global</footer>
        {/* <!-- ============================================================== --> */}
        {/* <!-- End footer --> */}
        {/* <!-- ============================================================== --> */}
      </div>
    </div>
  );
}

export default Dashboard;
