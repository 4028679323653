import React, { useEffect, useState, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Editor } from "@tinymce/tinymce-react";
import M from "materialize-css";
import Config from "../../../config/Config";
import { storage } from "../../../../firebase/FirebaseConfig";
import Select from "react-select";
import Breadcrumb from "../../components/Breadcrumb";

function EditProduct() {
  const history = useHistory();
  const { id } = useParams();

  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  // editor
  const editorRef = useRef(null);
  // State Variable
  const [weight, setWeight] = useState("");
  const [mrp, setMRP] = useState("");
  const [sellingPrice, setSellingPrice] = useState("");
  const [updatingPriceIndex, setUpdatingPriceIndex] = useState(null);
  const [isAddLoaded, setIsAddLoaded] = useState(true);
  const [defaultImage, setDefaultImage] = useState("");
  const [defaultImgProgress, setDefaultImgProgress] = useState("");

  const [product, setProduct] = useState({
    name: "",
    slug: "",
    parentCategories: [],
    categories: [],
    skus: "",
    priceVariants: [],
    type: "",
    // color: "",
    occasions: [],
    shape: "",
    images: [],
    isPhotoCake: "false",
    featuredCake: "false",
    bestseller: "false",
    breadType: "",
    description: "",

    isSameDayDelivery: "false",
    isSmallCake: "false",
  });

  const [previewImages, setPreviewImages] = useState([]);
  const [progressInfos, setProgressInfos] = useState([]);

  const [category, setCategory] = useState([]);
  const [parentCategory, setParentCategory] = useState([]);
  const [flavour, setFlavour] = useState([]);
  const [shapes, setShapes] = useState([]);
  const [occasions, setOccasions] = useState([]);
  const [types, setTypes] = useState([]);
  const [color, setColor] = useState([]);
  const [progress, setProgress] = useState("");

  const [selectPCat, setSelectPCat] = useState([]);
  const [selectSCat, setSelectSCat] = useState([]);
  const [selectOccasion, setSelectOccasion] = useState([]);
  const [selectShape, setSelectShape] = useState([]);
  const [selectFlavour, setSelectFlavour] = useState("");
  const [selectType, setSelectType] = useState("");
  const [selectColor, setSelectColor] = useState("");
  const [productDescriptions, setProductDescriptions] = useState("");
  const [productDetails, setProductDetails] = useState("");
  const [deliveryDetails, setDeliveryDetails] = useState("");
  const [careInstructions, setCareInstructions] = useState("");
  const [extraNotes, setExtraNotes] = useState("");

  const [pincodes, setPincodes] = useState([]);
  const [selectedPincodes, setSelectedPincodes] = useState([]);

  // Image Change
  const imageChangeHandler = (event, type) => {
    if (type == "default_image") {
      handleUpload(event.target.files[0], "", type);
    } else {
      if (event.target.files && event.target.files.length) {
        [...event.target.files].map((value, index) => {
          handleUpload(value, index);
        });
      }
    }
  };

  // File Delete Handler
  const fileDeleteHandler = (image, index, type) => {
    if (type == "default_image") {
      setDefaultImage("");
      setProduct({ ...product, defaultImage: "" });
      setDefaultImgProgress("");
    } else {
      let pImages = [...previewImages];
      pImages.splice(index, 1);

      let pInfos = [...progressInfos];
      pInfos.splice(index, 1);
      setProgressInfos(pInfos);
      setPreviewImages(pImages);
    }
    // // Create a reference to the file to delete
    // const fileRef = storage.refFromURL(image);
    // // Delete the file
    // fileRef
    //   .delete()
    //   .then(() => {
    //     // File deleted successfully
    //     if (type == "default_image") {
    //       setDefaultImage("");
    //       setDefaultImgProgress("");
    //     } else {
    //       let pImages = [...previewImages];
    //       pImages.splice(index, 1);

    //       let pInfos = [...progressInfos];
    //       pInfos.splice(index, 1);
    //       setProgressInfos(pInfos);
    //       setPreviewImages(pImages);
    //     }
    //   })
    //   .catch((error) => {
    //     // Uh-oh, an error occurred!
    //     M.toast({ html: error, classes: "bg-danger" });
    //   });
  };

  // Upload Image
  const handleUpload = (image, i, type) => {
    const name = Date.now() + "-" + image.name;
    const uploadTask = storage.ref(`products/${name}`).put(image);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );

        if (type == "default_image") {
          setDefaultImgProgress(progress);
        } else {
          let arrs = [...progressInfos];
          arrs[i] = progress;
          setProgressInfos((old) => {
            return [...arrs];
          });
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        storage
          .ref("products")
          .child(name)
          .getDownloadURL()
          .then((url) => {
            if (type == "default_image") {
              setDefaultImage(url);
              setProduct((old) => {
                return {
                  ...old,
                  defaultImage: url,
                };
              });
            } else {
              setPreviewImages((old) => [...old, url]);
              setProduct((old) => {
                return {
                  ...old,
                  images: [...old.images, { url }],
                };
              });
            }
          });
      }
    );
  };

  // Submit Handler
  const submitHandler = (evt) => {
    setIsAddLoaded(false);
    evt.preventDefault();

    const filteredPCat = selectPCat.map((value) => {
      return value.catId;
    });
    const filteredSCat = selectSCat.map((value) => {
      return value.catId;
    });

    const filteredOccasion = selectOccasion.map((value) => {
      return value.oId;
    });

    const filteredFlavours = selectFlavour.map((item) => item.value);

    let filteredPincodes = selectedPincodes.map((item) => item.value);

    const updateProduct = {
      ...product,
      deliveryPincodes: filteredPincodes,
      parentCategories: filteredPCat,
      categories: filteredSCat,
      flavours: filteredFlavours,
      type: selectType.value,
      color: selectColor.value,
      description: productDescriptions,
      productDetails,
      deliveryDetails,
      careInstructions,
      extraNotes,
      shape: selectShape.value,
      occasions: filteredOccasion,
      _id: undefined,
      id: undefined,
      createdAt: undefined,
      updatedAt: undefined,
      images: previewImages.map((img) => {
        return {
          url: img,
        };
      }),
    };

    delete updateProduct.flavour;

    fetch(`${Config.SERVER_URL}/product/${id}`, {
      method: "PUT",
      body: JSON.stringify(updateProduct),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            M.toast({ html: result.message, classes: "bg-success" });
            history.goBack();
          } else {
            const errorKeys = Object.keys(result.error);
            errorKeys.forEach((key) => {
              M.toast({ html: result.error[key], classes: "bg-danger" });
            });
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAddLoaded(true);
        },
        (error) => {
          setIsAddLoaded(true);
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  };

  // Get Product Details
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/product/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            setProduct(result.body);
            setProductDescriptions(result.body.description);
            setProductDetails(result.body?.productDetails);
            setDeliveryDetails(result.body?.deliveryDetails);
            setCareInstructions(result.body?.careInstructions);
            setExtraNotes(result.body?.extraNotes);
            setDefaultImage(result.body.defaultImage);
            setPreviewImages(
              result.body.images.map((item) => {
                return item.url;
              })
            );

            // Set Color
            if (result.body.color) {
              setSelectColor({
                label: result.body.color.name,
                value: result.body.color._id,
              });
            }

            // Set Shape
            if (result.body?.shape) {
              setSelectShape({
                label: result.body.shape.name,
                value: result.body.shape._id,
              });
            }

            // Set Flavours
            if (result.body?.flavours) {
              let flavours = result.body?.flavours?.map((flavour) => {
                return {
                  label: flavour.name,
                  value: flavour._id,
                };
              });
              setSelectFlavour(flavours);
            }

            // Set Pincodes
            if (result.body?.deliveryPincodes?.length) {
              let pincodes = result.body?.deliveryPincodes?.map((pincode) => {
                return {
                  label: pincode.pincode,
                  value: pincode._id,
                };
              });
              setSelectedPincodes(pincodes);
            }

            // Set Type
            if (result.body?.type) {
              setSelectType({
                label: result.body.type.name,
                value: result.body.type._id,
              });
            }

            // Set Parent Category
            if (result.body?.parentCategories?.length) {
              let c = [];
              result.body.parentCategories.forEach((item) => {
                c.push({ name: item.name, catId: item._id });
              });
              setSelectPCat(c);
            }

            // Set Occasions
            if (result.body?.occasions?.length) {
              let c = [];
              result.body.occasions.forEach((item) => {
                c.push({ name: item.name, oId: item._id });
              });
              setSelectOccasion(c);
            }

            // Set Sub Categories
            if (result.body?.categories?.length) {
              let c = [];
              result.body.categories.forEach((item) => {
                c.push({ name: item.name, catId: item._id });
              });
              setSelectSCat(c);
            }
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Parent Category
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/parent-category?skip=0&limit=200`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setParentCategory(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Shapes
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/shape?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let modifyForSelect = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setShapes(modifyForSelect);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Occasions
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/occasions?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let modifyForSelect = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setOccasions(modifyForSelect);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Category
  useEffect(() => {
    let url = `${Config.SERVER_URL}/category`;
    let body = "";
    let method = "GET";
    if (selectPCat.length) {
      const filter = selectPCat.map((value) => value.catId);
      url = `${url}/byParentCategory?limit=0`;
      body = { catId: filter };
      method = "POST";
    } else {
      url = `${url}?limit=0`;
      setSelectSCat([]);
    }

    fetch(url, {
      method: method,
      body: body != "" ? JSON.stringify(body) : null,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            if (!result.body.length) setSelectSCat([]);
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setCategory(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, [selectPCat]);

  // get Flavour
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/flavour?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setFlavour(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Types
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/type?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });
            setTypes(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Color
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/color?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let modifyForSelect = result.body.map((value) => {
              return { label: value.name, value: value._id };
            });
            setColor(modifyForSelect);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // get Pincodes
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/pincode?skip=0&limit=0`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let modifyForSelect = result.body.map((value) => {
              return { label: value.pincode, value: value._id };
            });
            setPincodes(modifyForSelect);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  // Update Price Handler
  const updatePriceVariantsHandler = (evt) => {
    evt.preventDefault();
    if (weight == "" || mrp == "" || sellingPrice == "") {
      M.toast({ html: "Please Fill Price Details", classes: "text-light" });
      return;
    }

    const isExist = product.priceVariants.find((value) => {
      if (
        value.mrp == mrp &&
        value.weight == weight &&
        value.sellingPrice == sellingPrice
      ) {
        return true;
      }
    });

    if (isExist) {
      M.toast({
        html: "Same Size,MRP & Price is already Exist",
        classes: "text-light",
      });
      setUpdatingPriceIndex(null);
      return;
    }

    let priceVariantsArray = product?.priceVariants;

    let filteredPrice = product?.priceVariants[updatingPriceIndex];
    filteredPrice.mrp = mrp;
    filteredPrice.weight = weight;
    filteredPrice.sellingPrice = sellingPrice;

    priceVariantsArray[updatingPriceIndex] = filteredPrice;

    setProduct({
      ...product,
      priceVariants: [...priceVariantsArray],
    });

    setUpdatingPriceIndex(null);
    setMRP("");
    setWeight("");
    setSellingPrice("");
  };

  // Add Price Varients
  const addPriceVariantsHandler = (evt) => {
    evt.preventDefault();
    if (weight == "" || mrp == "" || sellingPrice == "") {
      M.toast({ html: "Please Fill Price Details", classes: "text-light" });
      return;
    }

    const isExist = product.priceVariants.find((value) => {
      if (
        value.mrp == mrp &&
        value.weight == weight &&
        value.sellingPrice == sellingPrice
      ) {
        return true;
      }
    });

    if (isExist) {
      M.toast({ html: "Price is already Exist", classes: "text-light" });
      return;
    }
    setProduct({
      ...product,
      priceVariants: [...product.priceVariants, { mrp, weight, sellingPrice }],
    });
    setMRP("");
    setWeight("");
    setSellingPrice("");
  };

  // edit priceVariants
  const editPriceVariantsHandler = (i) => {
    setUpdatingPriceIndex(i);
    const filtered = product.priceVariants[i];
    setMRP(filtered.mrp);
    setWeight(filtered.weight);
    setSellingPrice(filtered.sellingPrice);
  };

  const deletePriceVariantsHandler = (i) => {
    const filtered = product.priceVariants.filter((value, index) => index != i);

    setProduct({ ...product, priceVariants: [...filtered] });
  };

  const addParentCategoryHandler = (evt) => {
    const cat = {
      catId: evt.value,
      name: evt.label,
    };

    const isExist = selectPCat.find((value) => {
      if (value.catId == cat.catId) {
        return true;
      }
    });

    if (isExist) {
      M.toast({ html: "Already Exist", classes: "text-light" });
      return;
    }

    setSelectPCat([...selectPCat, cat]);
  };

  const deleteParentCategoryHandler = (evt, value) => {
    evt.preventDefault();
    const filtered = selectPCat.filter(
      (cat, index) => cat.catId != value.catId
    );

    setSelectPCat([...filtered]);
  };

  const addOccasionHandler = (evt) => {
    const data = {
      oId: evt.value,
      name: evt.label,
    };

    const isExist = selectOccasion.find((value) => {
      if (value.oId == data.oId) {
        return true;
      }
    });

    if (isExist) {
      M.toast({ html: "Already Exist", classes: "text-light" });
      return;
    }

    setSelectOccasion([...selectOccasion, data]);
  };

  const deleteOccasionHandler = (evt, value) => {
    evt.preventDefault();
    const filtered = selectOccasion.filter((o, index) => o.oId != value.oId);

    setSelectOccasion([...filtered]);
  };

  const addSubCategoryHandler = (evt) => {
    const cat = {
      name: evt.label,
      catId: evt.value,
    };

    const isExist = selectSCat.find((value) => {
      if (value.catId == cat.catId) {
        return true;
      }
    });

    if (isExist) {
      M.toast({ html: "Already Exist", classes: "text-light" });
      return;
    }

    console.log(selectSCat);
    setSelectSCat([...selectSCat, cat]);
  };

  const deleteSubCategoryHandler = (evt, value) => {
    evt.preventDefault();
    const filtered = selectSCat.filter(
      (cat, index) => cat.catId != value.catId
    );

    setSelectSCat([...filtered]);
  };

  console.log(product);

  return (
    <div className="page-wrapper px-0 pt-0">
      <div className={"container-fluid"}>
        {/* Bread crumb and right sidebar toggle */}
        <Breadcrumb title={"PRODUCTS"} pageTitle={"Update Product"} />
        {/* End Bread crumb and right sidebar toggle */}

        {/* Listing Form */}
        <div className="row mt-2">
          <div className={"col-md-12 mx-auto"}>
            <form
              onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* PRODUCT DETAILS */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT DETAILS</h3>
                </div>

                {/* Product Name */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT NAME HERE !
                  </label>
                  <input
                    type="text"
                    value={product.name}
                    onChange={(evt) => {
                      setProduct({ ...product, name: evt.target.value });
                    }}
                    className="form-control"
                    placeholder={"Big Rectangle Cake"}
                  />
                </div>

                {/* Product Slug */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SLUG HERE !
                  </label>
                  <input
                    type="text"
                    value={product.slug}
                    onChange={(evt) =>
                      setProduct({ ...product, slug: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"big-rectangle-cake"}
                  />
                </div>

                {/* Product Sku */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT SKU !
                  </label>
                  <input
                    type="text"
                    value={product.sku}
                    onChange={(evt) =>
                      setProduct({ ...product, sku: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"CPXY1234"}
                  />
                </div>

                {/* MAXIMUM ORDER QUANTITY */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    MAXIMUM ORDER QUANTITY !
                  </label>
                  <input
                    type="text"
                    value={product.maximumOrderQuantity}
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        maximumOrderQuantity: evt.target.value,
                      })
                    }
                    className="form-control"
                    placeholder={"2"}
                  />
                </div>

                {/* Parent Category */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT PARENT CATEGORY !
                  </label>
                  <div className="">
                    <Select
                      options={parentCategory}
                      onChange={addParentCategoryHandler}
                    />
                  </div>
                </div>

                {/* Selected Parent Category */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECTED PARENT CATEGORY !
                  </label>
                  <div className="border p-2">
                    {selectPCat.map((value, index) => {
                      return (
                        <span
                          key={index}
                          className="badge badge-success p-2 btn mr-2 "
                          onClick={(evt) =>
                            deleteParentCategoryHandler(evt, value)
                          }
                        >
                          <i className="fa fa-times text-danger"></i>{" "}
                          {value.name}
                        </span>
                      );
                    })}
                  </div>
                </div>

                {/* OCCASIONS */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT OCCASIONS !
                  </label>
                  <div className="">
                    <Select options={occasions} onChange={addOccasionHandler} />
                  </div>
                </div>

                {/* SELECTED OCCASIONS */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECTED OCCASIONS !
                  </label>
                  <div className="border p-2">
                    {selectOccasion.map((value, index) => {
                      return (
                        <span
                          key={index}
                          className="badge badge-success p-2 btn mr-2 "
                          onClick={(evt) => deleteOccasionHandler(evt, value)}
                        >
                          <i className="fa fa-times text-danger"></i>{" "}
                          {value.name}
                        </span>
                      );
                    })}
                  </div>
                </div>

                {/* Sub Category */}
                {/* <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT SUB CATEGORY !
                  </label>

                  <div className="">
                    <Select
                      options={category}
                      onChange={addSubCategoryHandler}
                    />
                  </div>
                </div> */}

                {/* Selected Sub Category */}
                {/* <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECTED SUB CATEGORY !
                  </label>
                  <div className="border p-2">
                    {selectSCat.map((value, index) => {
                      return (
                        <span
                          key={index}
                          className="badge badge-info p-2 btn mr-2"
                          onClick={(evt) =>
                            deleteSubCategoryHandler(evt, value)
                          }
                        >
                          {value.name}
                        </span>
                      );
                    })}
                  </div>
                </div> */}

                {/* Flavour */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT FLAVOURS !
                  </label>

                  <Select
                    value={selectFlavour}
                    options={flavour}
                    isMulti={true}
                    onChange={(evt) => {
                      setSelectFlavour(evt);
                    }}
                  />
                </div>

                {/* Type */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT CAKE TYPE !
                  </label>

                  <Select
                    value={selectType}
                    options={types}
                    onChange={(evt) => {
                      setSelectType(evt);
                    }}
                  />
                </div>

                {/* Color */}
                {/* <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    COLOR HERE !
                  </label>

                  <Select
                    value={selectColor}
                    options={color}
                    onChange={(evt) => {
                      setSelectColor(evt);
                    }}
                  />
                </div> */}

                {/* Cake Shape */}
                <div className={"form-group col-md-6 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT CAKE SHAPE !
                  </label>

                  <div className="">
                    <Select
                      value={selectShape}
                      options={shapes}
                      onChange={(evt) => {
                        setSelectShape(evt);
                      }}
                    />
                  </div>
                </div>

                {/* Is Photo Cake*/}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PHOTO CAKE
                  </label>
                  <div className="d-flex my-3">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="cakeType3"
                        name="photoCake"
                        value={true}
                        checked={
                          product?.isPhotoCake?.toString() == "true"
                            ? true
                            : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            isPhotoCake: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="cakeType3"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="cakeType4"
                        name="photoCake"
                        checked={
                          product?.isPhotoCake?.toString() == "false"
                            ? true
                            : false
                        }
                        value={false}
                        onChange={(evt) => {
                          setProduct({
                            ...product,
                            isPhotoCake: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="cakeType4"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                {/* Featured Cake*/}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FEATURED CAKE
                  </label>
                  <div className="d-flex my-3">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="featuredCake1"
                        name="featuedCake"
                        value={"true"}
                        checked={
                          product?.featuredCake?.toString() == "true"
                            ? true
                            : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            featuredCake: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="featuredCake1"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="featuredCake2"
                        name="featuedCake"
                        checked={
                          product?.featuredCake?.toString() == "false"
                            ? true
                            : false
                        }
                        value={"false"}
                        onChange={(evt) => {
                          setProduct({
                            ...product,
                            featuredCake: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="featuredCake2"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                {/* BESTSELLER CAKE*/}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    BESTSELLER CAKE
                  </label>
                  <div className="d-flex my-3">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="bestseller1"
                        name="bestseller"
                        value={"true"}
                        checked={
                          product?.bestseller?.toString() == "true"
                            ? true
                            : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            bestseller: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="bestseller1"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="bestseller2"
                        name="bestseller"
                        checked={
                          product?.bestseller?.toString() == "false"
                            ? true
                            : false
                        }
                        value={"false"}
                        onChange={(evt) => {
                          setProduct({
                            ...product,
                            bestseller: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="bestseller2"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                {/* SAME DAY DELIVERY*/}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SAME DAY DELIVERY
                  </label>
                  <div className="d-flex my-3">
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="samedaydelivery"
                        name="isSameDayDelivery"
                        value={"true"}
                        checked={
                          product?.isSameDayDelivery?.toString() == "true"
                            ? true
                            : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            isSameDayDelivery: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="samedaydelivery"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="samedaydelivery2"
                        name="isSameDayDelivery"
                        checked={
                          product?.isSameDayDelivery?.toString() == "false"
                            ? true
                            : false
                        }
                        value={"false"}
                        onChange={(evt) => {
                          setProduct({
                            ...product,
                            isSameDayDelivery: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="samedaydelivery2"
                      >
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                {/* SELECT CAKE TYPE */}
                <div className={"col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT CAKE TYPE
                  </label>
                  <div className="d-flex my-3">
                    {/* Fresh Cream Cake */}
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="freshCreamCake"
                        name="cakeType"
                        value={"fresh_cream"}
                        checked={
                          product.cakeType == "fresh_cream" ? true : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            cakeType: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="freshCreamCake"
                      >
                        Fresh Cream Cake
                      </label>
                    </div>

                    {/* Semi Foundent Cake */}
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="semiFoundentCake"
                        name="cakeType"
                        value={"semi_fondent"}
                        checked={
                          product.cakeType == "semi_fondent" ? true : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            cakeType: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="semiFoundentCake"
                      >
                        Semi Foundent Cake
                      </label>
                    </div>

                    {/* Full Foundent Cake */}
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="fullFoundentCake"
                        name="cakeType"
                        value={"full_fondent"}
                        checked={
                          product.cakeType == "full_fondent" ? true : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            cakeType: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="fullFoundentCake"
                      >
                        Full Foundent Cake
                      </label>
                    </div>
                  </div>
                </div>

                {/* TYPE OF BREAD */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    TYPE OF BREAD HERE !
                  </label>
                  <input
                    type="text"
                    value={product.breadType}
                    onChange={(evt) =>
                      setProduct({ ...product, breadType: evt.target.value })
                    }
                    className="form-control"
                    placeholder={"Vanilla"}
                  />
                </div>

                {/* SELECT STATUS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELECT STATUS
                  </label>
                  <select
                    name=""
                    id=""
                    value={product.status}
                    onChange={(evt) => {
                      setProduct({ ...product, status: evt.target.value });
                    }}
                    className="form-control"
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Disabled</option>
                  </select>
                </div>
              </div>

              {/* SKUs */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT PRICE DETAILS</h3>
                </div>
                {/* Weight */}
                <div className={"form-group col-md-4"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CAKE WEIGHT
                  </label>
                  <input
                    type="text"
                    onChange={(evt) => setWeight(evt.target.value)}
                    name="weight"
                    value={weight}
                    className="form-control"
                    placeholder={"1/2 kg"}
                  />
                </div>

                {/* MRP */}
                <div className={"form-group col-md-3"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CAKE MRP
                  </label>
                  <input
                    type="text"
                    onChange={(evt) => setMRP(evt.target.value)}
                    name="mrp"
                    value={mrp}
                    className="form-control"
                    placeholder={"700"}
                  />
                </div>

                {/* Selling Price */}
                <div className={"form-group col-md-3"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SELLING PRICE
                  </label>
                  <input
                    type="text"
                    onChange={(evt) => setSellingPrice(evt.target.value)}
                    name="sellingPrice"
                    value={sellingPrice}
                    className="form-control"
                    placeholder={"599"}
                  />
                </div>
                <div className={"form-group col-md-2"}>
                  <div>
                    {updatingPriceIndex != null ? (
                      <button
                        className="btn btn-info rounded px-3 py-2"
                        type={"button"}
                        onClick={updatePriceVariantsHandler}
                      >
                        <span>
                          <i className="fas fa-pencil"></i> Update
                        </span>
                      </button>
                    ) : (
                      <button
                        className="btn btn-info rounded px-3 py-2"
                        type={"button"}
                        onClick={addPriceVariantsHandler}
                      >
                        <span>
                          <i className="fas fa-plus"></i> Add
                        </span>
                      </button>
                    )}
                  </div>
                </div>

                <div className="col-md-11">
                  {product.priceVariants.map((value, index) => {
                    return (
                      <div className="card m-0 mb-1">
                        <div className="card-body px-2 py-2 d-flex justify-content-between">
                          <h6>Cake Weight: {value.weight} </h6>
                          <h6>Cake MRP: {value.mrp} </h6>
                          <h6>Cake Selling Price: {value.sellingPrice} </h6>
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-info px-2 py-0 m-0 mr-1"
                              onClick={() => editPriceVariantsHandler(index)}
                            >
                              <span className="fa fa-pencil"></span>
                            </button>
                            <button
                              type="button"
                              className="btn btn-danger px-2 py-0 m-0"
                              onClick={() => deletePriceVariantsHandler(index)}
                            >
                              X
                            </button>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>

              {/* BANTO CAKE */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>BANTO CAKE</h3>
                </div>

                {/* BENTO CAKE*/}
                <div className={"col-md-12"}>
                  <div className="d-flex my-3">
                    <label htmlFor="" className="text-dark h6 active mr-3">
                      IS BENTO CAKE
                    </label>
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="bentoCakeYes"
                        name="bentoCake"
                        value={true}
                        checked={
                          product?.isBentoCake?.toString() == "true"
                            ? true
                            : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            isBentoCake: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="bentoCakeYes"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="bentoCakeNo"
                        name="bentoCake"
                        checked={
                          product?.isBentoCake?.toString() == "false"
                            ? true
                            : false
                        }
                        value={false}
                        onChange={(evt) => {
                          setProduct({
                            ...product,
                            isBentoCake: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" for="bentoCakeNo">
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    FLAVOUR DETAILS
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={product.bentoFlavourDetails}
                    onEditorChange={(content) => {
                      setProduct({ ...product, bentoFlavourDetails: content });
                    }}
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | image " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>

              {/* DELIVERY DETAILS */}
              <div className={"row shadow-sm bg-white mt-3 py-3 overflow-none"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>DELIVERY DETAILS</h3>
                </div>

                {/* DELIVERY CHARGE */}
                <div className={"col-md-12"}>
                  <div className="d-flex my-3">
                    <label htmlFor="" className="text-dark h6 active mr-3">
                      IS SMALL CAKE
                    </label>
                    <div className="custom-control custom-radio pl-0 ml-0">
                      <input
                        type="radio"
                        id="smallCakeYes"
                        name="smallCake"
                        value={true}
                        checked={
                          `${product.isSmallCake}` == "true" ? true : false
                        }
                        onChange={(evt) =>
                          setProduct({
                            ...product,
                            isSmallCake: evt.target.value,
                          })
                        }
                        className="custom-control-input"
                      />
                      <label
                        className="custom-control-label"
                        for="smallCakeYes"
                      >
                        YES
                      </label>
                    </div>
                    <div className="custom-control custom-radio">
                      <input
                        type="radio"
                        id="smallCakeNo"
                        name="smallCake"
                        checked={
                          `${product.isSmallCake}` == "false" ? true : false
                        }
                        value={false}
                        onChange={(evt) => {
                          setProduct({
                            ...product,
                            isSmallCake: evt.target.value,
                          });
                        }}
                        className="custom-control-input"
                      />
                      <label className="custom-control-label" for="smallCakeNo">
                        NO
                      </label>
                    </div>
                  </div>
                </div>

                <div className={"form-group col-md-12 mt-2 overflow-none"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DELIVERY PINCODE
                  </label>
                  {/* <textarea
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        deliveryPincodes: evt.target.value,
                      })
                    }
                    name="deliveryPincodes"
                    value={product.deliveryPincodes}
                    className="form-control"
                    rows={5}
                    placeholder={"Comma saparated picodes"}
                  ></textarea> */}

                  <div className="">
                    <Select
                      value={selectedPincodes}
                      options={pincodes}
                      onChange={(evt) => {
                        setSelectedPincodes(evt);
                      }}
                      isMulti={true}
                    />
                  </div>
                </div>
              </div>

              {/* PRODUCT DESCRIPTION */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT DESCRIPTION</h3>
                </div>

                {/* SHORT DESCRIPTION */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    SHORT DESCRIPTION
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        shortDescription: evt.target.value,
                      })
                    }
                    name="weight"
                    value={product.shortDescription}
                    className="form-control"
                    placeholder={"Write Short Descriptions"}
                  />
                </div>

                {/* PRODUCT TAGS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT TAGS
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        tags: evt.target.value,
                      })
                    }
                    name="weight"
                    value={product.tags}
                    className="form-control"
                    placeholder={"Snack, Organic, Brown"}
                  />
                </div>

                {/* LONG DESCTRIPTION */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    LONG DESCTRIPTION
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={productDescriptions}
                    onEditorChange={(content) => {
                      setProductDescriptions(content);
                    }}
                    init={{
                      height: 300,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | image " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>

                {/* PRODUCT DETAILS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT DETAILS
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={productDetails}
                    onEditorChange={(content) => {
                      setProductDetails(content);
                    }}
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | image " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>

                {/* DELIVERY DETAILS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    DELIVERY DETAILS
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={deliveryDetails}
                    onEditorChange={(content) => {
                      setDeliveryDetails(content);
                    }}
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | image " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>

                {/* CARE INSTRUCTIONS */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    CARE INSTRUCTIONS
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={careInstructions}
                    onEditorChange={(content) => {
                      setCareInstructions(content);
                    }}
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | image " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>

                {/* EXTRA NOTES */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    EXTRA NOTES
                  </label>
                  <Editor
                    apiKey="dxkecw9qym6pvb1b00a36jykem62593xto5hg5maqyksi233"
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    value={extraNotes}
                    onEditorChange={(content) => {
                      setExtraNotes(content);
                    }}
                    init={{
                      height: 250,
                      menubar: false,
                      plugins: [
                        "advlist",
                        "autolink",
                        "lists",
                        "link",
                        "image",
                        "charmap",
                        "preview",
                        "anchor",
                        "searchreplace",
                        "visualblocks",
                        "code",
                        "fullscreen",
                        "insertdatetime",
                        "media",
                        "table",
                        "code",
                        "help",
                        "wordcount",
                      ],
                      toolbar:
                        "undo redo | blocks | image " +
                        "bold italic forecolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help",
                      content_style:
                        "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                    }}
                  />
                </div>
              </div>

              {/* META DETAILS */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>META DETAILS</h3>
                </div>

                {/* META TITLE */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    META TITLE
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        metaTitle: evt.target.value,
                      })
                    }
                    name="weight"
                    value={product.metaTitle}
                    className="form-control"
                    placeholder={"Write Meta Title"}
                  />
                </div>

                {/* META DESCRIPTION */}
                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    META DESCRIPTION
                  </label>
                  <input
                    type="text"
                    onChange={(evt) =>
                      setProduct({
                        ...product,
                        metaDescription: evt.target.value,
                      })
                    }
                    name="weight"
                    value={product.metaDescription}
                    className="form-control"
                    placeholder={"Write Meta Description"}
                  />
                </div>
              </div>

              {/* PRODUCT IMAGES */}
              <div className={"row shadow-sm bg-white mt-3 py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>PRODUCT IMAGES</h3>
                </div>

                <div className={"form-group col-md-6"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT DEFAULT IMAGE
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={(evt) => imageChangeHandler(evt, "default_image")}
                    className="form-control"
                  />
                </div>

                <div className="col-md-6">
                  {defaultImage ? (
                    <div
                      className={"form-group text-center"}
                      style={{ position: "relative" }}
                    >
                      <img
                        style={{
                          maxHeight: "200px",
                          maxWidth: "200px",
                          border: "1px solid #5a5a5a",
                        }}
                        src={defaultImage}
                      />
                      <button
                        style={{
                          position: "absolute",
                          top: "40%",
                          left: "50%",
                        }}
                        type="button"
                        className="btn bg-light text-danger"
                        title={"Delete Image"}
                        onClick={(evt) =>
                          fileDeleteHandler(defaultImage, "", "default_image")
                        }
                      >
                        X
                      </button>
                    </div>
                  ) : (
                    ""
                  )}

                  {defaultImgProgress ? (
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${defaultImgProgress}%` }}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >
                        {defaultImgProgress}%
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* Products Multiple Images */}
                <div className={"form-group col-md-12"}>
                  <label htmlFor="" className="text-dark h6 active">
                    PRODUCT IMAGES
                  </label>
                  <input
                    type="file"
                    multiple
                    onChange={imageChangeHandler}
                    className="form-control"
                  />
                </div>

                {/*Multiple Image Preview */}
                <div className="col-md-12 mb-1">
                  <div className="row">
                    {previewImages.map((img, index) => {
                      return (
                        <div className={"form-group col-md-3"} key={index}>
                          <img
                            style={{
                              maxHeight: "100%",
                              maxWidth: "100%",
                              border: "1px solid #5a5a5a",
                            }}
                            src={img}
                          />
                          <button
                            style={{
                              position: "absolute",
                              top: "40%",
                              right: "45%",
                            }}
                            type="button"
                            className="btn bg-light text-danger"
                            title={"Delete Image"}
                            onClick={(evt) => fileDeleteHandler(img, index)}
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Multiple image prpgress */}
                <div className="col-md-12 mb-2">
                  <div className="row">
                    {progressInfos.map((info, index) => {
                      return (
                        <div className="col-md-3" key={index}>
                          <div className="progress">
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: `${info}%` }}
                              aria-valuemin="0"
                              aria-valuemax="100"
                            >
                              {info}%
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>

                {/* Submit Button */}
                <div className={"form-group col-md-6"}>
                  <button
                    className="btn btn-info rounded px-3 py-2"
                    type={"submit"}
                  >
                    {isAddLoaded ? (
                      <div>
                        <i className="fas fa-refresh"></i> Update Product
                      </div>
                    ) : (
                      <div>
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </div>
                    )}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProduct;
