import React, { useState, useEffect } from "react";
import Config from "../../../config/Config";
import M from "materialize-css";
import Breadcrumb from "../../components/Breadcrumb";
import { CSVLink } from "react-csv";
import Select from "react-select";

const DownloadProductCatalogueFb = () => {
  const [isAllRecordLoaded, setIsAllRecordLoaded] = useState(true);
  const [parentCategory, setParentCategory] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [tableHeaders, setTableHeaders] = useState([
    { label: "id", key: "id" },
    { label: "title", key: "title" },
    { label: "description", key: "description" },
    { label: "availability", key: "availability" },
    { label: "condition", key: "condition" },
    { label: "price", key: "price" },
    { label: "link", key: "link" },
    { label: "image_link", key: "image_link" },
    { label: "brand", key: "brand" },
    { label: "google_product_category", key: "google_product_category" },
    { label: "fb_product_category", key: "fb_product_category" },
    {
      label: "quantity_to_sell_on_facebook",
      key: "quantity_to_sell_on_facebook",
    },
    { label: "sale_price", key: "sale_price" },
    { label: "sale_price_effective_date", key: "sale_price_effective_date" },
    { label: "item_group_id", key: "item_group_id" },
    { label: "gender", key: "gender" },
    { label: "color", key: "color" },
    { label: "size", key: "size" },
    { label: "age_group", key: "age_group" },
    { label: "material", key: "material" },
    { label: "pattern", key: "pattern" },
    { label: "shipping", key: "shipping" },
    { label: "shipping_weight", key: "shipping_weight" },
  ]);
  const [tableData, setTableData] = useState([]);

  // get data from database
  useEffect(() => {
    setIsAllRecordLoaded(false);
    let url = `${Config.SERVER_URL}/product?status=All&limit=0`;
    if (selectedCategory?.value) {
      url = `${url}&parCatId=${selectedCategory?.value}`;
    }

    fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_admin_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            const dataArray = [];
            result.body.map((item, index) => {
              let data = {
                id: item._id,
                title: item.name,
                description: item.shortDescription,
                availability: "in stock",
                condition: "new",
                price: `${item?.priceVariants[0]?.sellingPrice.toFixed(2)} INR`,
                link: `https://thecakeinc.com/product/${item.slug}`,
                image_link: item.defaultImage,
                brand: "Thecakeinc",
                size: item?.priceVariants[0]?.weight,
                shipping_weight: item?.priceVariants[0]?.weight,
              };
              dataArray.push(data);
            });

            setTableData(dataArray);
            // setAllRecords(result.body || []);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
          setIsAllRecordLoaded(true);
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
          setIsAllRecordLoaded(true);
        }
      );
  }, [selectedCategory]);

  // get Parent Category
  useEffect(() => {
    fetch(`${Config.SERVER_URL}/parent-category?skip=0&limit=200`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("jwt_branch_token")}`,
      },
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.status === 200) {
            let f = result.body.map((v) => {
              return { label: v.name, value: v._id };
            });

            setParentCategory(f);
          } else {
            M.toast({ html: result.message, classes: "bg-danger" });
          }
        },
        (error) => {
          M.toast({ html: error, classes: "bg-danger" });
        }
      );
  }, []);

  return (
    <div className="page-wrapper">
      <div className="container-fluid">
        {/* <!-- ============================================================== --> */}
        {/* <!-- Bread crumb and right sidebar toggle --> */}
        {/* <!-- ============================================================== --> */}
        <Breadcrumb
          title={"PRODUCT CATELOGUE"}
          pageTitle={"Download Catalogue"}
        />

        {/* Add Form */}
        <div className="row">
          <div className={"col-md-11 mx-auto"}>
            <form
              //   onSubmit={submitHandler}
              className="form-horizontal form-material"
            >
              {/* Details */}
              <div className={"row shadow-sm bg-white py-3"}>
                <div className="col-md-12">
                  <h3 className={"my-3 text-info"}>Download Catalogue</h3>
                  {/* Select Categoey */}

                  <div className={"form-group col-md-4 overflow-none"}>
                    <label htmlFor="" className="text-dark h6 active">
                      SELECT CATEGORY !
                    </label>

                    <Select
                      value={selectedCategory}
                      options={parentCategory}
                      onChange={(evt) => {
                        setSelectedCategory(evt);
                      }}
                    />
                  </div>

                  <div className="col-md-6">
                    {isAllRecordLoaded ? (
                      <CSVLink
                        className="btn btn-info"
                        data={tableData}
                        headers={tableHeaders}
                        filename={`product-catalogue-for-${
                          selectedCategory?.label || "all-cakes"
                        }.csv`}
                      >
                        Download Catalogue
                      </CSVLink>
                    ) : (
                      <button className="btn btn-info" type="button">
                        <span
                          className="spinner-border spinner-border-sm mr-1"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        Loading..
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadProductCatalogueFb;
